export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 21, // Specify the desired width
      height: 21, // Specify the desired height
      fontSize: 10, // Adjust font size for the smaller avatar
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[0][1]}`,
  };
}

export function stringProfileAvatar(name) {
  if (!name) return { sx: { bgcolor: 'gray' }, children: '?' };

  const nameParts = name.split(' ');

  let initials = '';
  if (nameParts[0]) initials += nameParts[0][0]; // First letter of the first name
  if (nameParts[1]) initials += nameParts[1][0]; // First letter of the second name, if it exists

  return {
      sx: {
          bgcolor: stringToColor(name),
      },
      children: initials || '?',
  };
}
