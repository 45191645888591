import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNotification } from '../../../Constants/NotificationAlert';
import { Box, Button, FormControl, FormControlLabel, FormHelperText, Grid, MenuItem, Radio, RadioGroup, Select, Stack, TextField } from '@mui/material';
import { AuthContext } from '../../../Context/AuthContextProvider';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hrmsDomain } from '../../../Constants/api';
import useFetch from '../../../Constants/hooks/useFetch';
import { reloadPage } from '../../../redux/actions/action.mobile/load.action';
import { employeeinfo } from '../../../redux/actions/action.mobile/employeeinfo.action';
import CommonButton from '../../../Constants/CommonButton';
import dayjs from 'dayjs';
import axios from 'axios';

function Leave() {
  //redux
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { employeeData } = useSelector((state) => state.employeeReducer);

  //context
  const { notifySuccess, notifyError, notifyWarning, notifyInfo } = useNotification();
  const { authContext, setAuthContext } = useContext(AuthContext);
  const { userid, username, userrole, email, userphone, companyname, clientimage, companyid, companyemail, empid } = authContext.userData;
  const isProduction = process.env.NODE_ENV === "production";
  const options = useMemo(() => ({
    companyemail,
    companyid,
    employeeid: employeeData.empid,
    tableemployeeid: employeeData.id
  }), [companyemail, companyid, employeeData.empid, employeeData.id])
  //custom hooks
  const hasValidOptions = (options) => {
    return options && Object.values(options).every((value) => value !== null && value !== undefined && value !== "");
  };
  const { data: remainingLeaves, error: remainingLeaveError, loading } = useFetch(hasValidOptions(options) ? `${isProduction ? hrmsDomain : 'https://hrms.vliv.app'}/attedance/leave/remainingleaves` : null, options);
  //state
  const initialstate = {
    empname: "",
    empid: "",
    tableemployeeid: "",
    leavetype: "",
    leavebalance: "",
    fullday: false,
    firsthalf: false,
    secondhalf: false,
    appliedto: "",
    attachfile: "",
    fromdate: "",
    todate: "",
    noofdays: 0,
    reason: "",
    ccto: "",
    companyemail: companyemail,
    companyid: companyid,
    date: dayjs().format("YYYY-MM-DD"),
    hrstatus: "Pending",
    managerstatus: "Pending",
  }
  const [data, setData] = useState(initialstate);
  const [error, setError] = useState({});

  useEffect(() => {
    setData(initialstate);
    dispatch(reloadPage(false));
    if (companyemail) {
      const backendendData = {
        companyid,
        companyemail,
        email
      }
      dispatch(employeeinfo(backendendData));
    }

  }, [loadData, companyemail]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      companyid,
      companyemail,
      empname: employeeData.name,
      empid: employeeData.empid,
      tableemployeeid: employeeData.id,
      appliedto: employeeData.reportmanager,
    }));
  }, [employeeData]);

  useEffect(() => {
    calculateNoOfDays();
  }, [data.fromdate, data.todate, data.firsthalf, data.secondhalf, data.fullday]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...data, [name]: value };
    const updatedErrors = { ...error };

    if (name === "fromdate") {
      updatedData.todate = "";
      updatedErrors.fromdate = "";
      updatedErrors.todate = "";
    }

    if (name === "todate") {
      if (!updatedData.fromdate) {
        updatedErrors.todate = "First select From Date";
      } else {
        updatedErrors.todate = "";
      }
    }

    if (name === "leavetype") {
      updatedErrors.leavetype = "";
    }

    setData(updatedData);
    setError(updatedErrors);
  };

  const handleLeaveTypeChange = (e) => {
    const selectedLeaveType = e.target.value;
    const leave = remainingLeaves?.remainingLeaves && remainingLeaves?.remainingLeaves?.find(
      (leave) => leave.leavetype === selectedLeaveType
    );

    if (!leave.remaining || leave.remaining == "0") {
      notifyWarning("Can't apply since You have 0 leaves remaining");
      setData((prev) => ({ ...prev, leavetype: "", leavebalance: "" }));
      return;
    }

    setData((prevData) => ({
      ...prevData,
      leavetype: selectedLeaveType,
      leavebalance: leave ? leave?.remaining : "",
    }));

    setError((prevError) => ({
      ...prevError,
      leavetype: "",
      leavebalance: ""
    }))
  };

  const calculateNoOfDays = () => {
    if (!data.fromdate || !data.todate) {
      setData((prevData) => ({ ...prevData, noofdays: 0 }));
      return;
    }

    const fromDate = dayjs(data.fromdate);
    const toDate = dayjs(data.todate);
    let daysDiff = toDate.diff(fromDate, "day") + 1; // Adding 1 to include the end date

    if (data.fromdate == data.todate) {
      if (data.firsthalf && data.secondhalf) {
        // Do nothing, daysDiff remains the same
      } else if (data.firsthalf || data.secondhalf) {
        daysDiff = daysDiff / 2;
      }
    }

    setData((prevData) => ({
      ...prevData,
      noofdays: daysDiff,
      fullday: daysDiff > 1 ? true : prevData.fullday,
      firsthalf: daysDiff > 1 ? false : prevData.firsthalf,
      secondhalf: daysDiff > 1 ? false : prevData.secondhalf,
    }));
  };

  const validate = () => {
    const newErrors = {};

    //from date
    if (!data.fromdate) {
      newErrors.fromdate = "From date is required"
    }

    //to date
    if (!data.todate) {
      newErrors.todate = "To date is required"
    }

    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const res = await axios.post(`${isProduction ? hrmsDomain : 'https://hrms.vliv.app'}/attedance/leave/add`,
          data,
          { withCredentials: true });
        if (res.data.message == "Leave added successfully") {
          setData(initialstate)
          notifySuccess("Leave Request Raised");

        } else {
          notifyError(res.data.message)
        }
      }
      catch (err) {
        console.log("Error in applying leave", err.response.data.message)
        notifyError(err.response.data.message)
      }
    } else {
      console.log("Validation failed", error);
    }
  }

  return (
    <>
      <Grid container className='container'>
        <Grid item xs={12}>
          <Box>
            <form onSubmit={handleSubmit}>
              <Stack spacing={1}>
                <Box>
                  <label htmlFor="leavetype" className='mobileform-label'>Leave Type*</label>
                  <FormControl fullWidth error={!!error.leavetype}>
                    <Select
                      name="leavetype"
                      value={data.leavetype}
                      onChange={handleLeaveTypeChange}
                      fullWidth
                      required
                    >
                      {remainingLeaves?.remainingLeaves?.map((leave, ind) => (
                        <MenuItem key={ind} value={leave.leavetype}>
                          {leave.leavetype}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* Show error message with red color */}
                    {error.leavetype && (
                      <FormHelperText error={true}>{error.leavetype}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <label htmlFor="daytype" className='mobileform-label'>Day Type*</label>
                  <RadioGroup
                    row
                    sx={{
                      justifyContent: "space-between",

                      "& .css-j204z7-MuiFormControlLabel-root": {
                        marginRight: "0px",
                      }
                    }}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    disabled={data.noofdays > 1}
                  >
                    <FormControlLabel
                      value={data.fullday}
                      checked={data.fullday}
                      control={<Radio />}
                      label="Full Day"
                      onChange={(e) => { setData((prev) => ({ ...prev, fullday: e.target.checked, firsthalf: false, secondhalf: false })) }} />
                    <FormControlLabel
                      value={data.firsthalf}
                      checked={data.firsthalf}
                      control={<Radio />}
                      label="First Half"
                      onChange={(e) => { setData((prev) => ({ ...prev, fullday: false, firsthalf: e.target.checked, secondhalf: false })) }} />
                    <FormControlLabel
                      value={data.secondhalf}
                      checked={data.secondhalf}
                      control={<Radio />}
                      label="Second Half"
                      onChange={(e) => { setData((prev) => ({ ...prev, fullday: false, firsthalf: false, secondhalf: e.target.checked })) }}
                    />
                  </RadioGroup>
                </Box>
                <Box display={'flex'} gap={2}>
                  <Box width={"48%"}>
                    <label htmlFor="fromdate" className='mobileform-label'>From Date*</label>
                    <TextField
                      type="date"
                      name="fromdate"
                      value={data.fromdate}
                      onChange={handleChange}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ min: new Date().toISOString().split("T")[0] }} // Set minimum date to today
                      error={!!error.fromdate}
                      helperText={error.fromdate}
                    />
                  </Box>
                  <Box width={"48%"}>
                    <label htmlFor="fromdate" className='mobileform-label'>To Date*</label>
                    <TextField
                      type="date"
                      name="todate"
                      value={data.todate}
                      onChange={handleChange}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ min: data.fromdate || new Date().toISOString().split("T")[0] }} // Set minimum date to the selected "From Date"
                      error={!!error.todate}
                      helperText={error.todate}
                    />
                  </Box>
                </Box>
                <Box>
                  <label htmlFor="noofdays" className='mobileform-label'>No of Days: {data.noofdays}</label>
                </Box>
                <Box>
                  <label htmlFor="reason" className='mobileform-label'>Reason</label>
                  <TextField
                    name="reason"
                    value={data.reason}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={3}
                    placeholder="Enter reason"
                    variant="outlined"
                    error={!!error.reason}
                    helperText={error.reason}

                  />
                </Box>
                <Box display={'flex'} gap={2}>
                  <CommonButton type="button" variant="outlined" onClick={() => navigate(-1)}>Cancel</CommonButton>
                  <CommonButton type="submit" variant="contained">Submit</CommonButton>
                </Box>
              </Stack>
            </form>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Leave;