

export const imspage = "http://localhost:3000"

export const vlivDomain = "https://vliv.app";

export const hrmsDomain = "https://hrms.vliv.app"; // after deployment make it to hrms.vliv.app

export const vlivApi = "http://localhost:6001";

export const LoginApi = "https://hrms.vliv.app/user/loginhrms";

export const formpage = "http://localhost:3001";

export const masterGeneralLogoAdd = "https://hrms.vliv.app/general/logo/add";

export const masterGeneralLogoGet = "https://hrms.vliv.app/general/logo/get";

export const getRecruitmentApi = "https://hrms.vliv.app/recruitment/get";

export const addRecruitmentApi = "https://hrms.vliv.app/recruitment/add";

export const recruitmentGetByIdApi = "https://hrms.vliv.app/recruitment/getbyid";

export const editRecruitmentApi = "https://hrms.vliv.app/recruitment/update";

export const scheduleInterview = "https://hrms.vliv.app/jobapplication/interviewhistory";

export const multiDeleteRecruitmentApi = "https://hrms.vliv.app/recruitment/delete";

export const upsdateStatusRecruitmentApi = "https://hrms.vliv.app/recruitment/updatestatus";

export const addJobApplicationApi = "https://hrms.vliv.app/jobapplication/add";

export const getJobApplicationsApi = "https://hrms.vliv.app/jobapplication/get";

export const jobApplicationMultiStatusChangeApi = "https://hrms.vliv.app/jobapplication/updatestatus";

export const addAttendanceCheckin = "https://hrms.vliv.app/atte/checkin";

export const addAttendanceCheckout = "https://hrms.vliv.app/atte/checkout";

export const attendanceRequestApi = "https://hrms.vliv.app/atte/req/add"

export const getAttendanceRequestApi = "https://hrms.vliv.app/atte/req/get";

export const updateAttendanceReqStatusApi = "https://hrms.vliv.app/atte/updatereq";

export const getAllAttendancesByEmpID = "https://hrms.vliv.app/atte/getbyemployee";

export const masterGeneralValueGet = "https://hrms.vliv.app/general/value/get";

export const masterGeneralValueAdd = "https://hrms.vliv.app/general/value/add";

export const masterGeneralValueUpdate = "https://hrms.vliv.app/general/value/update";

export const masterGeneralValueDelete = "https://hrms.vliv.app/general/value/delete";

export const masterGeneralValueCategoryAdd = "https://hrms.vliv.app/general/value/category/add";

export const masterGeneralValueCategoryGet = "https://hrms.vliv.app/general/value/category/get";

export const masterGeneralValueCategoryUpdate = "https://hrms.vliv.app/general/value/category/update";

export const masterGeneralValueCategoryDelete = "https://hrms.vliv.app/general/value/category/delete";

export const masterGeneralValueSubCategoryAdd = "https://hrms.vliv.app/general/value/subcategory/add";

export const masterGeneralValueSubCategoryGet = "https://hrms.vliv.app/general/value/subcategory/get";

export const masterGeneralValueSubCategoryUpdate = "https://hrms.vliv.app/general/value/subcategory/update";

export const masterGeneralValueSubCategoryDelete = "https://hrms.vliv.app/general/value/subcategory/delete";

export const masterEmployeeSeriesAdd = "https://hrms.vliv.app/employee/series/add";

export const masterEmployeeSeriesGet = "https://hrms.vliv.app/employee/series/get";

export const masterEmployeeSeriesUpdate = "https://hrms.vliv.app/employee/series/update";

export const masterEmployeeSeriesDelete = "https://hrms.vliv.app/employee/series/delete";

export const masterEmployeePositionAdd = "https://hrms.vliv.app/employee/position/add";

export const masterEmployeePositionGet = "https://hrms.vliv.app/employee/position/get";

export const masterEmployeePositionUpdate = "https://hrms.vliv.app/employee/position/update";

export const masterEmployeePositionDelete = "https://hrms.vliv.app/employee/position/delete";

export const masterEmployeePositionMasterAdd = "https://hrms.vliv.app/employee/positionmaster/add";

export const masterEmployeePositionMasterGet = "https://hrms.vliv.app/employee/positionmaster/get";

export const masterEmployeePositionMasterUpdate = "https://hrms.vliv.app/employee/positionmaster/update";

export const masterEmployeePositionMasterDelete = "https://hrms.vliv.app/employee/positionmaster/delete";

export const masterEmployeeLeaveCategoryAdd = "https://hrms.vliv.app/employee/leave/add";

export const masterEmployeeLeaveCategoryGet = "https://hrms.vliv.app/employee/leave/get";

export const masterEmployeeLeaveCategoryUpdate = "https://hrms.vliv.app/employee/leave/update";

export const masterEmployeeLeaveCategoryDelete = "https://hrms.vliv.app/employee/leave/delete";

export const masterEmployeeConfigDirectoryAdd = "https://hrms.vliv.app/employee/config/add";

export const masterEmployeeConfigDirectoryGet = "https://hrms.vliv.app/employee/config/get";

export const masterEmployeeConfigDirectoryUpdate = "https://hrms.vliv.app/employee/config/update";

export const masterPayrollLWFAdd = "https://hrms.vliv.app/payroll/lwf/add";

export const masterPayrollLWFGet = "https://hrms.vliv.app/payroll/lwf/get";

export const masterPayrollLWFUpdate = "https://hrms.vliv.app/payroll/lwf/update";

export const masterPayrollLWFDelete = "https://hrms.vliv.app/payroll/lwf/delete";

export const masterPayrollFillingAdd = "https://hrms.vliv.app/payroll/filling/add";

export const masterPayrollFillingGet = "https://hrms.vliv.app/payroll/filling/get";

export const masterPayrollFillingUpdate = "https://hrms.vliv.app/payroll/filling/update";

export const masterPayrollFillingDelete = "https://hrms.vliv.app/payroll/filling/delete";

export const masterPayrollFillingTypeMasterAdd = "https://hrms.vliv.app/payroll/pftype/add";

export const masterPayrollFillingTypeMasterGet = "https://hrms.vliv.app/payroll/pftype/get";

export const masterPayrollFillingTypeMasterUpdate = "https://hrms.vliv.app/payroll/pftype/update";

export const masterPayrollFillingTypeMasterDelete = "https://hrms.vliv.app/payroll/pftype/delete";

export const masterPayrollFBPAdd = "https://hrms.vliv.app/payroll/fbp/add";

export const masterPayrollFBPGet = "https://hrms.vliv.app/payroll/fbp/get";

export const masterPayrollFBPUpdate = "https://hrms.vliv.app/payroll/fbp/update";

export const masterPayrollFBPDelete = "https://hrms.vliv.app/payroll/fbp/delete";

export const masterPolicyAdd = "https://hrms.vliv.app/payroll/policy/add"

export const masterPolicyGet = "https://hrms.vliv.app/payroll/policy/get"

export const masterPolicyUpdate = "https://hrms.vliv.app/payroll/policy/update"

export const masterPolicyDelete = "https://hrms.vliv.app/payroll/policy/delete"

export const masterPolicyFileUpload = "https://hrms.vliv.app/payroll/policy/fileupload"


export const masterAttendanceShiftAdd = "https://hrms.vliv.app/atted/shift/add"

export const masterAttendanceShiftGet = "https://hrms.vliv.app/atted/shift/get"

export const masterAttendanceShiftUpdate = "https://hrms.vliv.app/atted/shift/update"

export const masterAttendanceShiftDelete = "https://hrms.vliv.app/atted/shift/delete"


export const masterLeaveTypeAdd = "https://hrms.vliv.app/attedance/leavetype/add";

export const masterLeaveTypeGet = "https://hrms.vliv.app/attedance/leavetype/get";

export const masterLeaveTypeUpdate = "https://hrms.vliv.app/attedance/leavetype/update";

export const masterLeaveTypeDelete = "https://hrms.vliv.app/attedance/leavetype/delete";

export const masterLeaveTypeMasterAdd = "https://hrms.vliv.app/attedance/leavetype/master/add";

export const masterLeaveTypeMasterGet = "https://hrms.vliv.app/attedance/leavetype/master/get";

export const masterLeaveTypeMasterUpdate = "https://hrms.vliv.app/attedance/leavetype/master/update";

export const masterLeaveTypeMasterDelete = "https://hrms.vliv.app/attedance/leavetype/master/delete";

export const masterPayrollReimbursementTypeAdd = "https://hrms.vliv.app/payroll/category/add"

export const masterPayrollReimbursementTypeGet = "https://hrms.vliv.app/payroll/category/get"

export const masterPayrollReimbursementTypeDelete = "https://hrms.vliv.app/payroll/category/delete"

export const masterPayrollReimbursementReviewAdd = "https://hrms.vliv.app/payroll/reviewer/add"

export const masterPayrollReimbursementReviewGet = "https://hrms.vliv.app/payroll/reviewer/get"

export const masterPayrollReimbursementReviewDelete = "https://hrms.vliv.app/payroll/reviewer/delete"

export const masterEmployeeAssetAdd = "https://hrms.vliv.app/employee/asset/add";

export const masterEmployeeAssetGet = "https://hrms.vliv.app/employee/asset/get";

export const masterEmployeeAssetUpdate = "https://hrms.vliv.app/employee/asset/update";

export const masterEmployeeAssetDelete = "https://hrms.vliv.app/employee/asset/delete";

export const hrmsEmployeeRegistrationAdd = "https://hrms.vliv.app/employee/add";

export const hrmsEmployeeRegistrationGet = "https://hrms.vliv.app/employee/get";

export const hrmsEmployeeRegistrationUpdate = "https://hrms.vliv.app/employee/update";

export const hrmsEmployeeRegistrationDelete = "https://hrms.vliv.app/employee/delete";

export const hrmsEmployeeRegistrationGetByID = "https://hrms.vliv.app/employee/getbyid";

export const hrmsEmpRegPanUpload = "https://hrms.vliv.app/employee/pan";

export const hrmsEmpRegAadharUpload = "https://hrms.vliv.app/employee/aadhar";

export const hrmsEmpRegBankStatementUpload = "https://hrms.vliv.app/employee/bankstatement";

export const hrmsEmpRegPayslipUpload = "https://hrms.vliv.app/employee/payslip";

export const hrmsEmpRegPassportUpload = "https://hrms.vliv.app/employee/passport";

export const hrmsEmpRegExpCertificateUpload = "https://hrms.vliv.app/employee/expcertificate";

export const hrmsEmpRegRatingCardUpload = "https://hrms.vliv.app/employee/ratingcard";

export const hrmsEmpRegAppraisalUpload = "https://hrms.vliv.app/employee/appraisal";

export const hrmsEmpRegBondUpload = "https://hrms.vliv.app/employee/bond";

export const hrmsEmpRegBonafideUpload = "https://hrms.vliv.app/employee/bonafide";

export const hrmsEmpRegNDAUpload = "https://hrms.vliv.app/employee/nda";

export const hrmsSummaryHolidayAdd = "https://hrms.vliv.app/holiday/add";

export const hrmsSummaryHolidayGet = "https://hrms.vliv.app/holiday/get";

export const hrmsSummaryHolidayUpdate = "https://hrms.vliv.app/holiday/update";

export const hrmsSummaryHolidayDelete = "https://hrms.vliv.app/holiday/delete";

export const hrmsSummaryBirthdayAdd = "https://hrms.vliv.app/birthday/add";

export const hrmsSummaryBirthdayGet = "https://hrms.vliv.app/birthday/get";

export const hrmsSummaryBirthdayUpdate = "https://hrms.vliv.app/birthday/update";

export const hrmsSummaryBirthdayDelete = "https://hrms.vliv.app/birthday/delete";

export const hrmsSummaryAnnouncementAdd = "https://hrms.vliv.app/announcement/add";

export const hrmsSummaryAnnouncementGet = "https://hrms.vliv.app/announcement/get";

export const hrmsSummaryAnnouncementUpdate = "https://hrms.vliv.app/announcement/update";

export const hrmsSummaryAnnouncementDelete = "https://hrms.vliv.app/announcement/delete";

export const hrmsAttendanceLeaveAdd = "https://hrms.vliv.app/attedance/leave/add";

export const hrmsAttendanceLeaveGet = "https://hrms.vliv.app/attedance/leave/get";

export const hrmsAttendanceLeaveUpdate = "https://hrms.vliv.app/attedance/leave/update";

export const hrmsAttendanceLeaveDelete = "https://hrms.vliv.app/attedance/leave/delete";

export const hrmsAttendanceLeaveGetByID = "https://hrms.vliv.app/attedance/leave/getbyid";

export const hrmsAttendanceLeaveGetByEmployee = "https://hrms.vliv.app/attedance/leave/getbyemployee";

export const hrmsAttendanceLeaveFileAttach = "https://hrms.vliv.app/attedance/leave/fileattach";

export const hrmsAttendanceLeaveReport = "https://hrms.vliv.app/attedance/leave/report";

export const hrmsAttendanceLeaveReportByID = "https://hrms.vliv.app/attedance/leave/reportbyid";

export const hrmsTimeAttendanceGetbyID = "https://hrms.vliv.app/atte/getbyemployeeanddate";

export const hrmsTimeAttendanceGet = "https://hrms.vliv.app/atte/get";

export const employeeExitRequestApi = "https://hrms.vliv.app/exit/add";


export const employeeReimbursementAddapi = "https://hrms.vliv.app/employee/reimbursement/add"

export const getAllExitRequestApi = "https://hrms.vliv.app/exit/get"

export const updateEmployeeExit = "https://hrms.vliv.app/exit/update";

export const deleteEmployeeExit = "https://hrms.vliv.app/exit/delete";

export const employeeExitGetbyID = "https://hrms.vliv.app/exit/getbyid";

export const addOrganisationgoalKpiMasterApi = "https://hrms.vliv.app/performance/orgkpi/add"

export const addEmployeeDevelopementKpiMasterApi = "https://hrms.vliv.app/performance/empkpi/add"

export const addTeamBuildingMasterApi = "https://hrms.vliv.app/performance/teamkpi/add";

export const getPerformanceApi = "https://hrms.vliv.app/performance/get";

export const documentGenerationGetApi = "https://hrms.vliv.app/document/get";

export const updatedocumentgenerationapi = "https://hrms.vliv.app/document/update";

export const addTaskApi = "https://hrms.vliv.app/task/add";

export const getAllTagsApi = "https://hrms.vliv.app/task/tag";

export const getTasksApi = "https://hrms.vliv.app/task/get";

export const updateTaskStatus = "https://hrms.vliv.app/task/updatestatus";

export const departmentMasterApi = "https://hrms.vliv.app/employee/position/get";

export const addEventsApi = "https://hrms.vliv.app/event/add";

export const getEvents = "https://hrms.vliv.app/event/get";

export const deleteEventsApi = "https://hrms.vliv.app/event/delete";

export const editEventApi = "https://hrms.vliv.app/event/update";

export const addAllowanceApi = "https://hrms.vliv.app/structure/add";

export const getAllowanceApi = "https://hrms.vliv.app/payroll/config/get";

export const updateAllowancesApi = "https://hrms.vliv.app/payroll/config/update";

export const getPayrollInputsApi = "https://hrms.vliv.app/payroll/monthpayroll/get";

export const updatePayrollInputsApi = "https://hrms.vliv.app/payroll/updatepayrollinput";

export const multiStatusPayrollInputsAPI = "https://hrms.vliv.app/payroll/updatestatus";

export const projectAddApi = "https://hrms.vliv.app/taskmanagement/project/add";

export const projectGetApi = "https://hrms.vliv.app/taskmanagement/project/get";

export const projectUpdateApi = "https://hrms.vliv.app/taskmanagement/project/update";

export const projectDeleteApi = "https://hrms.vliv.app/taskmanagement/project/delete";






export const googleMapSearchApiKey = "AIzaSyB3Mbhn-OA-Xd_CIQ0MtgzqgpX5Q3-RF54";