import axios from "axios";
import { vlivApi, vlivDomain } from "../../../Constants/api";
import { ALL_USERS_DATA } from "../../actionTypes";
const isProduction = process.env.NODE_ENV === "production";

export const allUsers = (body, setAlertType, setOpen, setMsg) => async (dispatch) => {
    try {
        // dispatch({ type: "RELOAD", payload: true });
        const response = await axios.post(`${isProduction ? vlivDomain : vlivApi}/user/getusers`, body, { withCredentials: true });
      
        if (response.data.data.length > 0) {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                dispatch({ type: ALL_USERS_DATA, payload: response.data.data });
            }, 500)
        }
        else {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                dispatch({ type: ALL_USERS_DATA, payload: [] });
            }, 500)
        }

    }
    catch (error) {
        console.log("Error in fetching all users data", error.response?.data.message)
        setAlertType("error");
        setOpen(true);
        setMsg(error.message);
    }

};