import { projectGetApi } from "../../../Constants/api";
import axios from "axios";
import { ALL_PROJECT_DATA } from "../../actionTypes";

export const allproject = (setAlertType, setOpen, setMsg) => async (dispatch) => {
    try {
        // dispatch({ type: "RELOAD", payload: true });
        const response = await axios.post(projectGetApi, {}, { withCredentials: true });
        // console.log("response", response)
        if (response.data.data.length > 0) {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                dispatch({ type: ALL_PROJECT_DATA, payload: response.data.data });
            }, 500)
        }
        else {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                dispatch({ type: ALL_PROJECT_DATA, payload: [] });
            }, 500)
        }

    }
    catch (error) {
        console.log("Error in fetching all project data", error)
        setAlertType("error");
        setOpen(true);
        setMsg(error.message);
    }

};

export const commondelete = (api, idarray, setAlertType, setOpen, setMsg) => async (dispatch) => {
    try {
        const res = await axios.post(api, { idarray }, { withCredentials: true });

        if (res.data.message == "Deleted successfully") {
                setAlertType("success");
                setOpen(true);
                setMsg(res.data.message);
                dispatch({ type: "RELOAD", payload: true });
            
        }
        else {
                setAlertType("success");
                setOpen(true);
                setMsg(res.data.message);
                dispatch({ type: "RELOAD", payload: true });
        }

    }
    catch (error) {
        console.log("Error in delete all project data", error?.response.data.message)
        setAlertType("error");
        setOpen(true);
        setMsg(error.message);
        dispatch({ type: "RELOAD", payload: true });
    }

};