import {
    Box,
    Button,
    Drawer,
    Grid,
    TextField,
    Typography,
    Divider,
    IconButton,
    Stack,
    Autocomplete,
    Checkbox,
    Avatar,
    FormHelperText,
} from "@mui/material";
import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { addOrganisationgoalKpiMasterApi, projectAddApi, projectUpdateApi } from "../../../../Constants/api";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { AuthContext } from "../../../../Context/AuthContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { allUsers } from "../../../../redux/actions/actions.master/user.actions";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { stringProfileAvatar } from "../../../../Constants/StringAvatar";
import Loader from "../../../../Constants/Loader";
import { formatDate } from "../../../../Constants/dateFormat";

const CommonDrawer = lazy(() => import("../../../../Constants/CommonDrawer"));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ProjectDrawer({ drawerOpen, handleDrawerClose, isSmallScreen, editItem, isEditItem }) {

    //context
    const { authContext } = useContext(AuthContext);
    const { userid, username, userrole, email, userphone, companyname, clientimage, companyid, companyemail } = authContext.userData;

    //redux
    const dispatch = useDispatch();
    const { allUserData } = useSelector((state) => state.userReducer)

    //state
    const initialdata = {
        projectname: "",
        startdate: "",
        enddate: "",
        createdby: "",
        assigneduser: [],
        description: "",
    };
    const [data, setData] = useState(initialdata);
    const [error, setError] = useState({});
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState("");
    const [alertType, setAlertType] = useState("success");

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "startdate") {
            const formattedDate = formatDate(value);
            setData({ ...data, [name]: formattedDate });
            setError((data) => ({ ...data, [name]: "" }));
            return;
        }

        if (name === "enddate") {
            const formattedDate = formatDate(value);
            setData({ ...data, [name]: formattedDate });
            setError((data) => ({ ...data, [name]: "" }));
            return;
        }

        setData({ ...data, [name]: value });

        setError((data) => ({ ...data, [name]: "" }));

    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const validate = () => {
        const newErrors = {};

        // Project name validation
        if (!data.projectname) {
            newErrors.projectname = "Project name is required";
        }

        // Startdate validation
        if (!data.startdate) {
            newErrors.startdate = "Start date cannot be empty";
        }
        // else {
        //     const isValidDate = dayjs(data.startdate, "DD/MM/YYYY", true).isValid();
        //     if (!isValidDate) {
        //         newErrors.startdate = "Start date must be a valid date in DD/MM/YYYY format";
        //     }
        // }

        //Enddate validation
        if (!data.enddate) {
            newErrors.enddate = "End date can not be empty";
        }

        //Assigned user validation
        if (data.assigneduser.length == 0) {
            newErrors.assigneduser = "Assigned user can not be empty";
        }

        setError(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    useEffect(() => {
        const body = {
            companyemail: companyemail,
            companyid: companyid
        }

        if (!isEditItem) {
            setData(initialdata)
        }

        if (username && drawerOpen) {
            setData((prev) => ({ ...prev, createdby: username }))
            dispatch(allUsers(body, setOpen, setMsg, setAlertType));
        }

        if (isEditItem) {
            const assignedUsers = editItem.assigneduser.map((userId) =>
                allUserData.find((user) => user.id === userId)
            ).filter(Boolean);
            setData((prev) => ({ ...prev, ...editItem, assigneduser: assignedUsers }));
        }

    }, [dispatch, drawerOpen])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                if (!isEditItem) {
                    let res = await axios.post(projectAddApi, data, { withCredentials: true });
                    if (res.data.message == "Project created successfully") {
                        setAlertType("success");
                        setOpen(true);
                        setMsg(res.data.message);
                        setTimeout(() => {
                            setData(initialdata);
                            handleDrawerClose();
                        }, 1000);
                    }
                } else {
                    let res = await axios.post(projectUpdateApi, data, { withCredentials: true });
                    if (res.data.message == "Project Updated Successfully") {
                        setAlertType("success");
                        setOpen(true);
                        setMsg(res.data.message);
                        setTimeout(() => {
                            setData(initialdata);
                            handleDrawerClose();
                        }, 1000);
                    }
                }

            } catch (error) {
                console.log(error, "error");
                setAlertType("error");
                setOpen(true);
                setMsg(error.response.data.message || "Something went wrong");
            }


        } else {
            console.log("Validation failed", error);
        }
    }

    return (
        <>
            <Suspense fallback={<Loader />}>
                <CommonDrawer drawerOpen={drawerOpen} handleDrawerClose={handleDrawerClose} drawertitle={`${!isEditItem ? "Add Project" : "Edit Project"}`}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <label htmlFor="projectname">Project Name</label>
                                <TextField
                                    type="text"
                                    name="projectname"
                                    placeholder="Enter Projectname"
                                    value={data.projectname}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    error={!!error.projectname}
                                    helperText={error.projectname}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <label htmlFor="createdby">Created By</label>
                                <TextField
                                    type="text"
                                    name="Created By"
                                    value={data.createdby}
                                    // onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    error={!!error.createdby}
                                    helperText={error.createdby}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <label htmlFor="startdate">Start Date</label>
                                <TextField
                                    type="date"
                                    placeholder="DD/MM/YYYY"
                                    name="startdate"
                                    value={data.startdate.split("/").reverse().join("-")}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    error={!!error.startdate}
                                    helperText={error.startdate}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <label htmlFor="enddate">End Date</label>
                                <TextField
                                    type="date"
                                    placeholder="DD/MM/YYYY"
                                    name="enddate"
                                    value={data.enddate.split("/").reverse().join("-")}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    error={!!error.enddate}
                                    helperText={error.enddate}
                                />
                            </Grid>
                            <Grid item xl={12} md={12}>
                                <label className='form-label'>Assigned User</label>
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={allUserData}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.username || ''}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    value={data.assigneduser}
                                    renderOption={(props, option, { selected }) => {
                                        const { key, ...optionProps } = props;
                                        return (
                                            <li key={key} {...optionProps}>
                                                <Stack direction="row" spacing={2} alignItems='center'>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        checked={selected}
                                                    />
                                                    <Avatar {...stringProfileAvatar(option?.username)} style={{ margin: "0rem 1rem" }} />
                                                    {option.username}
                                                </Stack>
                                            </li>
                                        );
                                    }}
                                    onChange={(event, newValue) => {
                                        setData(prev => ({
                                            ...prev,
                                            assigneduser: newValue
                                        }));
                                        setError((prev) => ({
                                            ...prev,
                                            assigneduser: '',

                                        }));
                                    }}
                                    // style={{ width: 500 }}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            placeholder="Select User"
                                            error={!!error.assigneduser}
                                            helperText={error.assigneduser}
                                        />
                                    )}
                                    sx={{ marginTop: "1rem" }}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <label className='form-label'>Description</label>
                                <TextField
                                    name="description"
                                    placeholder="Enter Description"
                                    value={data.description}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                flexDirection="row-reverse"
                                flexWrap={"wrap"}
                                gap={1}
                                padding={1}
                            >
                                <Button type="submit" className="saveBtn">
                                    {!isEditItem ? "Save" : "Update"}
                                </Button>
                                <Button
                                    onClick={handleDrawerClose}
                                    sx={{ ml: 2 }}
                                    className="cancelBtn"
                                    variant="outlined"
                                >
                                    Cancel
                                </Button>


                            </Grid>
                        </Grid>


                    </form>


                </CommonDrawer>
            </Suspense>
            <AlertPopUp
                open={open}
                msg={msg}
                handleClose={handleClose}
                type={alertType}
            />
        </>
    )
}

export default ProjectDrawer;