import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getEmpPosition } from "../../../../redux/actions/actions.master/empPosition.actions";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import {
  addEmpReg,
  getEmpReg,
} from "../../../../redux/actions/actions.hrms/empRegistration.actions";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";

const CreateEmployee = ({ dropDowns, allEmp }) => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  let companyid = companyInfo.companyid ? companyInfo.companyid : "";
  const [candidateData, setCandidateData] = useState([]);
  const dispatch = useDispatch();
  const { positionData, error, loading } = useSelector(
    (state) => state.empPositionReducer
  );

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  //for success alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [data, setData] = useState({
    name: "",
    department: "",
    position: "",
    profile: "",
    role: "User",
    reportmanager: "",
    emptype: "",
    level: "",
    gender: "",
    location: "",
    branch: "",
    otapplicable: "",
    attendancescheme: "",
    subdepartment: "",
    company: "",
    confirmdate: "",
    joiningdate: "",
    status: "",
    probationperiod: "",
    noticeperiod: "",
    experience: "",
    ctc: "",
    variablepay: "",
    swifttype: "",
    address: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    contactno: "",
    dob: "",
    personalemail: "",
    linkedin: "",
    twitter: "",
    maritalstatus: "",
    nationality: "",
    residentstatus: "",
    countryoforigin: "",
    birthplace: "",
    religion: "",
    physically: "",
    isinternational: "",
    bank: "",
    accountno: "",
    accountholder: "",
    ifsc: "",
    pan: "",
    panno: "",
    aadhar: "",
    aadharno: "",
    passport: "",
    passportno: "",
    expcertificate: "",
    payslip: "",
    bankstatement: "",
    educationarray: [],
    paaddress: "",
    pacountry: "",
    pastate: "",
    pacity: "",
    papincode: "",
    ecname: "",
    ecrelation: "",
    eccontactno: "",
    ecaddress: "",
    eccountry: "",
    ecstate: "",
    eccity: "",
    ecpincode: "",
    ecemail: "",
    verifystatus: "",
    verifydate: "",
    agency: "",
    remarks: "",
    monitor: "",
    cpu: "",
    keyboard: "",
    mouse: "",
    chair: "",
    ratingcard: "",
    appraisal: "",
    bond: "",
    bonafide: "",
    nda: "",
    candidateid: "",
    isepf: false,
    isesic: false,
    companyemployeeid:"",
    companyid: companyInfo.companyid,
    companyemail: companyInfo.companyemail,
  });

  //for input validations
  const [formData, setFormData] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({
    email: false,
  });

  // Regex patterns for validation
  const regexPatterns = {
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // Basic email pattern
  };

  const errorMessages = {
    email:
      "Invalid email address. Please enter a valid email in the format example@domain.com.",
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Only validate ESIC if it's not empty (since it's optional)
    if (regexPatterns[name].test(value)) {
      setErrors({
        ...errors,
        [name]: false,
      });
    } else {
      setErrors({
        ...errors,
        [name]: true,
      });
    }
  };

  const isFormValid = () => {
    // Check if there are any errors or empty fields
    return (
      Object.values(errors).every((error) => !error) &&
      Object.values(formData).every((field) => field !== "")
    );
  };

  // for input validations change

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleCandidChange = async (value) => {
    // console.log(value, "event");
    const selectedCandid =
      candidateData.length > 0 &&
      value &&
      value.candidateid &&
      candidateData.find((candid) => {
        return candid.candidateid == value.candidateid;
      });
    // console.log(selectedCandid, "candidate");
    const {
      aadhar,
      aadharno,
      variablepay,
      name,
      dob,
      pan,
      panno,
      bankstatement,
      ecname,
      currentaddress,
      primarycontact,
      emailid,
      martialstatus,
      expletter,
      salaryslip,
      permanentaddress,
      ecprelation,
      ecpcontactno,
      ecpaddress,
      degreemarksheet,
      degreename,
      degreepercent,
      degreeyear,
      mastermarksheet,
      mastername,
      masterpercent,
      masteryear,
      tenmarksheet,
      tenpercent,
      tenyear,
      twelvemarksheet,
      twelvepercent,
      twelveyear,
      bank,
      accountno,
      accountholder,
      ifsc,
      candidateid,
    } = selectedCandid || {};
    // console.log(selectedCandid,"selectedcandidate");
    setData((prev) => ({
      ...prev,
      aadhar,
      aadharno,
      variablepay,
      name,
      dob,
      pan,
      panno,
      bankstatement,
      ecname,
      bank,
      accountno,
      accountholder,
      ifsc,
      candidateid,
      address: currentaddress,
      contactno: primarycontact,
      email: emailid,
      personalemail: emailid,
      maritalstatus: martialstatus,
      expcertificate: expletter,
      payslip: salaryslip,
      paaddress: permanentaddress,
      ecrelation: ecprelation,
      eccontactno: ecpcontactno,
      ecaddress: ecpaddress,
      educationarray: [
        {
          educationname: "10th",
          educationtype: "Secondary",
          marksheet: tenmarksheet,
          percentage: tenpercent,
          passoutyear: tenyear,
        },
        {
          educationname: "12th",
          educationtype: "inter",
          marksheet: twelvemarksheet,
          percentage: twelvepercent,
          passoutyear: twelveyear,
        },
        {
          educationname: degreename,
          educationtype: "degree",
          marksheet: degreemarksheet,
          percentage: degreepercent,
          passoutyear: degreeyear,
        },
        {
          educationname: mastername,
          educationtype: "masterdegree",
          marksheet: mastermarksheet,
          percentage: masterpercent,
          passoutyear: masteryear,
        },
      ],
    }));

    setFormData((prev) => ({
      ...prev,
      email: emailid,
    }));

    try {
      let res = await axios.post(
        "https://hrms.vliv.app/jobapplication/recruitmentbycandidateid",
        { companyid, candidateid: selectedCandid.candidateid },
        { withCredentials: true }
      );
      // console.log(res, "res recruitment details");
      if (res.data && res.data.data && res.data.data.length > 0) {
        const temp = res.data.data[0];
        setData((prev) => ({
          ...prev,
          level: temp.level,
          branch: temp.branch,
          emptype: temp.employeetype,
          position: temp.positions,
          department: temp.department,
        }));
      } else {
        setData((prev) => prev);
      }
    } catch (error) {
      console.log(error, "error in getting recruitment details");
    }
  };

  const handleCreate = () => {
    // console.log(data, "saveData");
    if (
      !data.name ||
      !data.department ||
      !data.position ||
      !data.profile ||
      !data.reportmanager ||
      !data.emptype ||
      !data.level ||
      !data.location ||
      !data.branch
      // ||
      // !data.otapplicable ||
      // !data.attendancescheme
    ) {
      setShowEmptyError(true);
      setMsg("Please fill all the fields");
      setAlertType("error");
      setOpen(true);
      return;
    }
    const obj = { ...data, ...formData };
    dispatch(addEmpReg(obj, setOpen, setMsg, setAlertType));
    setData({
      name: "",
      department: "",
      position: "",
      role: "User",
      reportmanager: "",
      emptype: "",
      level: "",
      gender: "",
      location: "",
      branch: "",
      otapplicable: "",
      attendancescheme: "",
      subdepartment: "",
      company: "",
      confirmdate: "",
      joiningdate: "",
      status: "",
      probationperiod: "",
      noticeperiod: "",
      experience: "",
      ctc: "",
      variablepay: "",
      swifttype: "",
      address: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      contactno: "",
      dob: "",
      email: "",
      personalemail: "",
      linkedin: "",
      twitter: "",
      maritalstatus: "",
      nationality: "",
      residentstatus: "",
      countryoforigin: "",
      birthplace: "",
      religion: "",
      physically: "",
      isinternational: "",
      pan: "",
      panno: "",
      aadhar: "",
      aadharno: "",
      passport: "",
      passportno: "",
      expcertificate: "",
      payslip: "",
      bankstatement: "",
      educationarray: [],
      paaddress: "",
      pacountry: "",
      pastate: "",
      pacity: "",
      papincode: "",
      ecname: "",
      ecrelation: "",
      eccontactno: "",
      ecaddress: "",
      eccountry: "",
      ecstate: "",
      eccity: "",
      ecpincode: "",
      ecemail: "",
      verifystatus: "",
      verifydate: "",
      agency: "",
      remarks: "",
      monitor: "",
      cpu: "",
      keyboard: "",
      mouse: "",
      chair: "",
      ratingcard: "",
      appraisal: "",
      bond: "",
      bonafide: "",
      nda: "",
      companyid: companyInfo.companyid,
      companyemail: companyInfo.companyemail,
    });
    setFormData({
      email: "",
    });
    setDrawerOpen(false);
  };

  useEffect(() => {
    dispatch(
      getEmpPosition({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch, drawerOpen]);

  useEffect(() => {
    dispatch(
      getEmpReg({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch, drawerOpen]);

  const fetchData = async (companyid) => {
    try {
      // setLoading(true);
      const res = await axios.post(
        "https://hrms.vliv.app/candidate/candidatedropdown",
        { companyid },
        { withCredentials: true }
      );
      // console.log(res, "yegfg");
      if (res.data?.data.length) {
        setCandidateData((prev) => res.data.data);
      } else {
        setCandidateData([]);
      }
      // setLoading(false);
    } catch (err) {
      console.error(err);
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(companyid);
  }, []);

  // console.log(candidateData, "candidateData");
  // console.log(allEmp,"allEmps");

  return (
    <>
      <Button className="saveBtn" onClick={() => setDrawerOpen(true)}>
        Create Employee
      </Button>
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          width: "50vw",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "50vw",
            boxSizing: "border-box",
            "@media (max-width: 600px)": {
              width: "90vw", // adjust width for smaller screens
            },
          },
        }}
      >
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
          my={1}
          px={1}
        >
          <p className="drawer-heading">Employee Registration</p>
          <Box width={"50%"}>
            <Typography component="h6" style={{ marginBottom: "3px" }}>
              Candidate ID
            </Typography>
            <Autocomplete
              fullWidth
              size="small"
              options={
                candidateData.length > 0
                  ? candidateData.filter(
                    (candidate) =>
                      !allEmp.find(
                        (emp) =>
                          String(emp.candidateid) ==
                          String(candidate.candidateid)
                      )
                  )
                  : []
              }
              getOptionLabel={(option) =>
                `${option.candidateid} ${option.name}`
              }
              renderInput={(params) => (
                <TextField size="small" sx={{ px: 0, py: 0 }} {...params} />
              )}
              onChange={(event, newValue) => handleCandidChange(newValue)}
            />
          </Box>
        </Box>
        <Divider />
        <Box
          p={2}
          flexGrow={1}
          overflow="auto"
          className="drawer-form-container"
        >
          <Grid
            container
            justifyContent={"space-between"}
            marginTop="5px"
            marginBottom="20px"
          >
            <Grid item md={5.9}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                fullWidth
                className="formInputs"
                placeholder="Enter Name"
                value={data.name}
                onChange={handleChange}
                name="name"
                error={!data.name && showEmptyError}
              />
            </Grid>
            <Grid item md={5.9}>
              <Typography
                Typography
                component="h6"
                style={{ marginBottom: "3px" }}
              >
                Department <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                size="small"
                fullWidth
                options={
                  (dropDowns.length > 0 &&
                    dropDowns
                      .filter(
                        (dropd) =>
                          dropd.position == "department" &&
                          dropd?.status?.toLowerCase() == "active"
                      )
                      .map((elem) => elem.name)) ||
                  []
                }
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    sx={{ px: 0, py: 0 }}
                    {...params}
                    // className="formInputs"
                    inputProps={{
                      ...params.inputProps,
                      // className: "selectInput",
                    }}
                    error={!data.department && showEmptyError}
                  />
                )}
                value={data.department}
                onChange={(event, newValue) =>
                  handleChange({
                    target: { name: "department", value: newValue },
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            marginTop="20px"
            marginBottom="20px"
          >
            <Grid item md={5.9}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Position <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                size="small"
                fullWidth
                options={
                  (dropDowns.length > 0 &&
                    dropDowns
                      .filter(
                        (dropd) =>
                          dropd.position == "position" &&
                          dropd.status.toLowerCase() == "active"
                      )
                      .map((elem) => elem.name)) ||
                  []
                }
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    sx={{ px: 0, py: 0 }}
                    {...params}
                    // className="formInputs"
                    inputProps={{
                      ...params.inputProps,
                      // className: "selectInput",
                    }}
                    error={!data.position && showEmptyError}
                  />
                )}
                value={data.position}
                onChange={(event, newValue) =>
                  handleChange({
                    target: { name: "position", value: newValue },
                  })
                }
              />
            </Grid>
            <Grid item md={5.9}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Profile <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                size="small"
                fullWidth
                options={
                  (dropDowns.length > 0 &&
                    dropDowns
                      .filter(
                        (dropd) =>
                          dropd.position == "profile" &&
                          dropd.status.toLowerCase() == "active"
                      )
                      .map((elem) => elem.name)) ||
                  []
                }
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    sx={{ px: 0, py: 0 }}
                    {...params}
                    // className="formInputs"
                    inputProps={{
                      ...params.inputProps,
                      // className: "selectInput",
                    }}
                    error={!data.profile && showEmptyError}
                  />
                )}
                value={data.profile}
                onChange={(event, newValue) =>
                  handleChange({ target: { name: "profile", value: newValue } })
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            marginTop="20px"
            marginBottom="20px"
          >
            <Grid item md={5.9}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Reporting Manager <span style={{ color: "red" }}>*</span>
              </Typography>
              <Select
                fullWidth
                className="formInputs"
                inputProps={{
                  className: "selectInput",
                }}
                value={data.reportmanager}
                onChange={handleChange}
                name="reportmanager"
                error={!data.reportmanager && showEmptyError}
              >
                {allEmp.length > 0 &&
                  allEmp
                    ?.filter(
                      (emp) =>
                        emp.role?.toLowerCase() == "masteradmin" ||
                        emp.role?.toLowerCase() == "manager"
                    )
                    .map((em) => (
                      <MenuItem value={em.name} key={em.id}>
                        {em.name}
                      </MenuItem>
                    ))}
              </Select>
            </Grid>
            <Grid item md={5.9}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Employee Type <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                size="small"
                fullWidth
                options={
                  (dropDowns.length > 0 &&
                    dropDowns
                      .filter(
                        (dropd) =>
                          dropd.position == "employeetype" &&
                          dropd.status.toLowerCase() == "active"
                      )
                      .map((elem) => elem.name)) ||
                  []
                }
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    sx={{ px: 0, py: 0 }}
                    {...params}
                    // className="formInputs"
                    inputProps={{
                      ...params.inputProps,
                      // className: "selectInput",
                    }}
                    error={!data.emptype && showEmptyError}
                  />
                )}
                value={data.emptype}
                onChange={(event, newValue) =>
                  handleChange({ target: { name: "emptype", value: newValue } })
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            marginTop="20px"
            marginBottom="20px"
          >
            <Grid item md={5.9}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Level <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                size="small"
                fullWidth
                options={
                  (dropDowns.length > 0 &&
                    dropDowns
                      .filter(
                        (dropd) =>
                          dropd.position == "level" &&
                          dropd.status.toLowerCase() == "active"
                      )
                      .map((elem) => elem.name)) ||
                  []
                }
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    sx={{ px: 0, py: 0 }}
                    {...params}
                    // className="formInputs"
                    inputProps={{
                      ...params.inputProps,
                      // className: "selectInput",
                    }}
                    error={!data.level && showEmptyError}
                  />
                )}
                value={data.level}
                onChange={(event, newValue) =>
                  handleChange({ target: { name: "level", value: newValue } })
                }
              />
            </Grid>
            <Grid item md={5.9}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Email <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="formInputs"
                fullWidth
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                error={errors.email}
                helperText={errors.email && errorMessages.email}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            marginTop="20px"
            marginBottom="20px"
          >
            <Grid item md={5.9}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Location <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                size="small"
                fullWidth
                options={
                  (dropDowns.length > 0 &&
                    dropDowns
                      .filter(
                        (dropd) =>
                          dropd.position == "location" &&
                          dropd.status.toLowerCase() == "active"
                      )
                      .map((elem) => elem.name)) ||
                  []
                }
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    sx={{ px: 0, py: 0 }}
                    {...params}
                    // className="formInputs"
                    inputProps={{
                      ...params.inputProps,
                      // className: "selectInput",
                    }}
                    error={!data.location && showEmptyError}
                  />
                )}
                value={data.location}
                onChange={(event, newValue) =>
                  handleChange({
                    target: { name: "location", value: newValue },
                  })
                }
              />
            </Grid>
            <Grid item md={5.9}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Branch <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                size="small"
                fullWidth
                options={
                  (dropDowns.length > 0 &&
                    dropDowns
                      .filter(
                        (dropd) =>
                          dropd.position == "branch" &&
                          dropd.status.toLowerCase() == "active"
                      )
                      .map((elem) => elem.name)) ||
                  []
                }
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    sx={{ px: 0, py: 0 }}
                    {...params}
                    // className="formInputs"
                    inputProps={{
                      ...params.inputProps,
                      // className: "selectInput",
                    }}
                    error={!data.branch && showEmptyError}
                  />
                )}
                value={data.branch}
                onChange={(event, newValue) =>
                  handleChange({ target: { name: "branch", value: newValue } })
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            marginTop="20px"
            marginBottom="20px"
          >
            <Grid item md={5.9}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                EPF Applicable
              </Typography>
              <Autocomplete
                size="small"
                fullWidth
                options={["Yes", "No"]}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    sx={{ px: 0, py: 0 }}
                    {...params}
                    // className="formInputs"
                    inputProps={{
                      ...params.inputProps,
                      // className: "selectInput",
                    }}
                  // error={!data.isepf && showEmptyError}
                  />
                )}
                value={data.isepf === true ? "Yes" : "No"} // Convert boolean to string for display
                onChange={(event, newValue) =>
                  handleChange({
                    target: {
                      name: "isepf",
                      value: newValue === "Yes" ? true : false,
                    },
                  })
                }
              />
            </Grid>

            <Grid item md={5.9}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                ESIC Applicable
              </Typography>
              <Autocomplete
                size="small"
                fullWidth
                options={["Yes", "No"]}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    sx={{ px: 0, py: 0 }}
                    {...params}
                    // className="formInputs"
                    inputProps={{
                      ...params.inputProps,
                      // className: "selectInput",
                    }}
                  // error={!data.isepf && showEmptyError}
                  />
                )}
                value={data.isesic === true ? "Yes" : "No"} // Convert boolean to string for display
                onChange={(event, newValue) =>
                  handleChange({
                    target: {
                      name: "isesic",
                      value: newValue === "Yes" ? true : false,
                    },
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            marginTop="20px"
            marginBottom="20px"
          >
            <Grid item md={5.9}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                OT Applicable
              </Typography>
              <Autocomplete
                size="small"
                fullWidth
                options={["Yes", "No"]}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    sx={{ px: 0, py: 0 }}
                    {...params}
                    // className="formInputs"
                    inputProps={{
                      ...params.inputProps,
                      // className: "selectInput",
                    }}
                  // error={!data.otapplicable && showEmptyError}
                  />
                )}
                value={data.otapplicable}
                onChange={(event, newValue) =>
                  handleChange({
                    target: { name: "otapplicable", value: newValue },
                  })
                }
              />
            </Grid>

            <Grid item md={5.9}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Attendance Scheme
              </Typography>
              <Autocomplete
                size="small"
                fullWidth
                options={
                  (dropDowns.length > 0 &&
                    dropDowns
                      .filter(
                        (dropd) =>
                          dropd.position == "attendancescheme" &&
                          dropd.status.toLowerCase() == "active"
                      )
                      .map((elem) => elem.name)) ||
                  []
                }
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    sx={{ px: 0, py: 0 }}
                    {...params}
                    // className="formInputs"
                    inputProps={{
                      ...params.inputProps,
                      // className: "selectInput",
                    }}
                  // error={!data.attendancescheme && showEmptyError}
                  />
                )}
                value={data.attendancescheme}
                onChange={(event, newValue) =>
                  handleChange({
                    target: { name: "attendancescheme", value: newValue },
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            marginTop="20px"
            marginBottom="20px"
          >
            <Grid item md={5.9}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Company Employee Id
              </Typography>
              <TextField
                type="text"
                placeholder="Enter Your Company Employee ID"
                value={data.companyemployeeid}
                onChange={handleChange}
                name="companyemployeeid"
                className="formInputs"
                fullWidth
                error={!data.companyemployeeid && showEmptyError}
              />
            </Grid>
          </Grid>
          <div>
            {loading ? (
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                className="saveBtn"
              >
                Create
              </LoadingButton>
            ) : (
              <Button
                className="saveBtn"
                onClick={handleCreate}
                disabled={!isFormValid()}
              >
                Create
              </Button>
            )}
          </div>
        </Box>
      </Drawer>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default CreateEmployee;
