import {
    Box,
    Button,
    Drawer,
    Typography,
    Divider,
    IconButton,
    Stack,
} from "@mui/material";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";

function CommonDrawer({ drawerOpen, handleDrawerClose, drawertitle, children }) {
    return (
        <>
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose} sx={{
                "& .MuiDrawer-paper": {
                    width: "700px",
                    maxWidth: "90vw",
                },
            }}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    px={2}
                    py={1}
                >
                    <Typography variant="h6">{drawertitle}</Typography>
                    <IconButton onClick={handleDrawerClose}>
                        <CancelIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Stack
                    sx={{
                        justifyContent: "space-between",
                    }}
                    className="drawer-form-container"
                >
                    {children}
                </Stack>

            </Drawer>
        </>
    )
}

export default CommonDrawer;