import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import axios from "axios";
import {
  getPayrollInputsApi,
  updatePayrollInputsApi,
} from "../../../../Constants/api";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import Cookies from "js-cookie";
import Loader from "../../../../Constants/Loader";


const PayrollInputs = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Initialize the current month and year
  const [currentDate, setCurrentDate] = useState(dayjs());

  // Handler to go to the previous month
  const handlePreviousMonth = () => {
    const newDate = currentDate.subtract(1, "month");
    setCurrentDate(newDate);
  };

  // Handler to go to the next month
  const handleNextMonth = () => {
    const newDate = currentDate.add(1, "month");
    setCurrentDate(newDate);
  };

  // Handler to go to the previous year
  const handlePreviousYear = () => {
    const newDate = currentDate.subtract(1, "year");
    setCurrentDate(newDate);
  };

  // Handler to go to the next year
  const handleNextYear = () => {
    const newDate = currentDate.add(1, "year");
    setCurrentDate(newDate);
  };

  const getData = async () => {
    try {
      setLoading(true);
      let res = await axios.post(getPayrollInputsApi, {
        companyemail,
        companyid,
        month: currentDate.format("MMMM"),
        year: currentDate.format("YYYY"),
      }, { withCredentials: true });
      console.log("res.data", res.data);
      res.data && res.data.length > 0 ? setData(res.data) : setData([]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setAlertType("error");
      setOpen(true);
      setMsg("Error while getting payroll inputs");
      setLoading(false);
    }
  };

  const handleChange = (row, e) => {
    const { name, value } = e.target;
    const updatedRow = { ...row, [name]: value };
    const updatedFilteredData = filteredData.map((item) =>
      item.id === row.id ? updatedRow : item
    );
    setFilteredData(updatedFilteredData);
  };

  const handleButtonClick = async (row, value) => {
    if (row.status && row.status !== "Pending") {
      return;
    } else {
      if (row.holdstatus == value) {
        return;
      } else {
        try {
          let res = await axios.post(updatePayrollInputsApi, {
            ...row,
            holdstatus: value,
          }, { withCredentials: true });
          if (res.data.message) {
            setAlertType("success");
            setOpen(true);
            setMsg("Status Changed..");
            getData();
          }
        } catch (error) {
          console.log(error, "errorrrrr");
          setAlertType("error");
          setOpen(true);
          setMsg(error.response.data.error);
        }
      }
    }
  };

  const handleBlur = async (row, name) => {
    try {
      let res = await axios.post(updatePayrollInputsApi, row, { withCredentials: true });
      if (res.data.message) {
        setAlertType("success");
        setOpen(true);
        setMsg(`${name} Marked..`);
        getData();
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.error);
    }
  };

  useEffect(() => {
    getData();
  }, [currentDate]);

  useEffect(() => {
    const filteredRows = data.filter((row) =>
      row.name.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    setFilteredData(filteredRows);
  }, [searchKeyword, data]);

  const columns = [
    { field: "employeeid", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "department", headerName: "Department", flex: 1 },
    { field: "position", headerName: "Position", flex: 1 },
    { field: "fandf", headerName: "F&F", flex: 1 },
    { field: "sum_of_reimbursement", headerName: "Reimbursement", flex: 1 },
    {
      field: "paydays",
      headerName: "Pay Days",
      flex: 1,
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent={`${params.row.status.toLowerCase() != "paid" ? "center" : "start"}`}
          mt={0.5}
        >
          {params.row.status.toLowerCase() != "paid" ?
            <TextField
              type="number"
              size="small"
              id="outlined-basic"
              variant="outlined"
              name="paydays"
              onChange={(e) => handleChange(params.row, e)}
              value={params.row.paydays}
              onBlur={() => handleBlur(params.row, "Paydays")}
            /> : !params.row.paydays ? 0 : params.row.paydays}
        </Box>
      ),
    },
    {
      field: "incentive",
      headerName: "Incentive",
      flex: 1,
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent={`${params.row.status.toLowerCase() != "paid" ? "center" : "start"}`}
          mt={0.5}
        >
          {params.row.status.toLowerCase() != "paid" ? <TextField
            size="small"
            id="outlined-basic"
            variant="outlined"
            name="incentive"
            onChange={(e) => handleChange(params.row, e)}
            value={params.row.incentive}
            onBlur={() => handleBlur(params.row, "Incentive")}
          /> : !params.row.incentive ? 0 : params.row.incentive}
        </Box>
      ),
    },
    {
      field: "empdeduction",
      headerName: "Deductions",
      flex: 1,
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent={`${params.row.status.toLowerCase() != "paid" ? "center" : "start"}`}
          mt={0.5}
        >
          {params.row.status.toLowerCase() != "paid" ? <TextField
            size="small"
            id="outlined-basic"
            variant="outlined"
            name="empdeduction"
            onChange={(e) => handleChange(params.row, e)}
            value={params.row.empdeduction}
            onBlur={() => handleBlur(params.row, "Deduction")}
          /> : !params.row.empdeduction ? 0 : params.row.empdeduction}
        </Box>
      ),
    },
    {
      field: "holdstatus",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <ButtonGroup
          disableElevation
          variant="contained"
          aria-label="Disabled button group"
        >
          <Button
            sx={{
              textTransform: "capitalize",
              backgroundColor:
                params.row.holdstatus.toLowerCase() == "release" ? "#37668F" : "#98A2B3",
            }}
            onClick={() => handleButtonClick(params.row, "Release")}
          >
            Release
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
              backgroundColor:
                params.row.holdstatus.toLowerCase() == "hold" ? "#37668F" : "#98A2B3",
            }}
            onClick={() => handleButtonClick(params.row, "hold")}
          >
            Hold
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  // console.log(currentDate.format('MMMM'), "month");
  // console.log(currentDate.format('YYYY'),"year");

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        p={2}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="h6">Payroll</Typography>

          {/* Month Navigation */}
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            border={"1px solid #0B4A6F"}
            borderRadius={2}
          >
            <IconButton onClick={handlePreviousMonth}>
              <NavigateBeforeIcon />
            </IconButton>
            <Typography variant="body1" component="span" color={"#0B4A6F"}>
              {currentDate.format("MMMM")}
            </Typography>
            <IconButton onClick={handleNextMonth}>
              <NavigateNextIcon />
            </IconButton>
          </Box>

          {/* Year Navigation */}
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            border={"1px solid #0B4A6F"}
            borderRadius={2}
          >
            <IconButton onClick={handlePreviousYear}>
              <NavigateBeforeIcon />
            </IconButton>
            <Typography variant="body1" component="span" color={"#0B4A6F"}>
              {currentDate.year()}
            </Typography>
            <IconButton onClick={handleNextYear}>
              <NavigateNextIcon />
            </IconButton>
          </Box>
        </Box>

        {/* Search Field */}
        <Box>
          <TextField
            placeholder="Search Employee..."
            variant="outlined"
            size="small"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            InputProps={{
              startAdornment: (
                <Box display="flex" alignItems="center" gap={1}>
                  <Search />
                </Box>
              ),
            }}
          />
        </Box>
      </Box>

      {/* DataGrid */}
      <Box my={2}>
        <Paper style={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={loading ? [] : filteredData}
            columns={columns}
            // checkboxSelection
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 8 },
              },
            }}
            pageSizeOptions={[8, 16, 24, 32, data.length]}
            autoHeight
            components={{
              LoadingOverlay: Loader,
            }}
            loading={loading}
          />
        </Paper>
      </Box>

      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default PayrollInputs;
