import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FiDownload } from "react-icons/fi";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import axios from "axios";
import {
  getPayrollInputsApi,
  multiStatusPayrollInputsAPI,
  updatePayrollInputsApi,
} from "../../../../Constants/api";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import Cookies from "js-cookie";
import Loader from "../../../../Constants/Loader";
import { getPayrollStatusColor } from "../../../../Constants/Colors";
import MultiStatusChangePopUp from "./MultiStatusChangePopUp";
import { FaRegFilePowerpoint } from "react-icons/fa6";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import {
  dummyExceldata,
  excelHeaders,
  transformData,
} from "../../../../Constants/constants";
import SalaryCards from "./SalaryCards";

import ViewSalary from "./ViewSalary";
import SalarySlipComponent from "./SalarySlipComponent";

const Salary = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [paidPayrollData, setPaidPayrollData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [PrevPaidPayrolls, setPrevPaidPayrolls] = useState(0);
  const [CurrHoldPayrolls, setCurrHoldPayrolls] = useState(0);
  const [CurrNetPayrolls, setCurrNetPayrolls] = useState(0);

  const [checkBoxValue, setCheckBoxValue] = React.useState("By System");

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //for import file
  const fileInputRef = useRef(null);

  const handleUploadBtnClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // console.log("Selected file:", file);

      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        // Transform data to required format
        const transformedData = jsonData.map((row) => ({
          employeeid: row["Serial No."] || "",
          companyemployeeid: row["Company Employee Id"] || "",
          month: row["Month"] || "",
          year: row["Year"] || "",
          ctc: row["CTC"] || "",
          basicpay: row["Basic Allowances"] || "",
          houserentpay: row["House Rent Allowance"] || "",
          specialpay: row["Special Allowance"] || "",
          educationpay: row["Educational Allowances"] || "",
          medicalpay: row["Medical Allowances"] || "",
          insurancepay: row["Insurances"] || "",
          internetpay: row["Telephone & Internet Allowances"] || "",
          vehiclepay: row["Vehicle & Driver Fuel Allowances"] || "",
          travelpay: row["Travel Allowances"] || "",
          otherallowancepay: row["Other Allowances"] || "",
          otherdeductionpay: row["Other Deductions"] || "",
          pfpay: row["EPF"] || "",
          pfemployeepay: row["PF Employee"] || "",
          pfemployerpay: row["PF Employer"] || "",
          esicemployeepay: row["ESIC Employee Pay"] || "",
          esicemployerpay: row["ESIC Employer"] || "",
          conveyancepay: row["Conveyance Pay"] || "",
          ptaxpay: row["Professional Tax"] || "",
          tdspay: row["TDS"] || "",
          grosspay: row["Gross Pay"] || "",
          epfwages: row["EPF Wages"] || "",
          ewswages: row["EWS Wages"] || "",
          edliwages: row["EDLI Wages"] || "",
          ee: row["EE"] || "",
          eps: row["EPS"] || "",
          er: row["ER"] || "",
          bank: row["Bank"] || "",
          accountno: row["Accountno."] || "",
          accountholder: row["Account Holder"] || "",
          ifsc: row["IFSC"] || "",
          incentive: row["Incentives"] || "",
          earning: row["Earning"] || "",
          totalgross: row["Total Gross"] || "",
          lopdays: row["LOP Days"] || "",
          lop: row["LOP"] || "",
          deduction: row["Deduction"] || "",
        }));

        // console.log(transformedData, "transformdata");

        try {
          // console.log(transformedData, "transform2");
          let res = await axios.post("https://hrms.vliv.app/payroll/upload", {
            companyemail,
            companyid,
            jsondata: transformedData,
          }, { withCredentials: true });
          // console.log("excel file upload ", res)
          if (res.data.message == "Payroll uploaded successfully") {
            setAlertType("success");
            setOpen(true);
            setMsg("File Uploaded...");
            getData();
          }
        } catch (error) {
          console.log(error);
          setOpen(true);
          setAlertType("error");
          setMsg(error.response.data.message || "Something went wrong");
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  //for sample download
  const downloadSample = () => {
    // Create a worksheet with headers
    // Create a worksheet with headers
    const ws = XLSX.utils.aoa_to_sheet([excelHeaders, ...dummyExceldata]);

    // Calculate column widths based on header length with padding
    const columnWidths = excelHeaders.map((header) => ({
      wch: header.length + 4,
    }));

    // Set the column widths
    ws["!cols"] = columnWidths;

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate the Excel file
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Save the file
    saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      "sample.xlsx"
    );
  };

  // Initialize the current month and year
  const [currentDate, setCurrentDate] = useState(dayjs());

  // Handler to go to the previous month
  const handlePreviousMonth = () => {
    const newDate = currentDate.subtract(1, "month");
    setCurrentDate(newDate);
  };

  // Handler to go to the next month
  const handleNextMonth = () => {
    const newDate = currentDate.add(1, "month");
    setCurrentDate(newDate);
  };

  // Handler to go to the previous year
  const handlePreviousYear = () => {
    const newDate = currentDate.subtract(1, "year");
    setCurrentDate(newDate);
  };

  // Handler to go to the next year
  const handleNextYear = () => {
    const newDate = currentDate.add(1, "year");
    setCurrentDate(newDate);
  };

  const StatusCellRenderer = (params) => {
    // console.log(params.row,"params row")
    const [status, setStatus] = React.useState(
      params.row.status == "Pending" || !params.row.status
        ? "Pending"
        : params.row.status
    );

    const handleStatusChange = async (event) => {
      const newStatus = event.target.value;
      setStatus(newStatus);
      // // Call your update API here
      try {
        let res = await axios.post(updatePayrollInputsApi, {
          ...params.row,
          status: newStatus,
        }, { withCredentials: true });
        if (res.data.message) {
          setAlertType("success");
          setOpen(true);
          setMsg("Status Changed...");
          getData();
        }
      } catch (error) {
        console.log(error);
        setOpen(true);
        setAlertType("error");
        setMsg(error.response.data.message || "Something went wrong");
      }
    };

    if (params.row.holdstatus === "hold") {
      return (
        <Button color={getPayrollStatusColor(params.row.holdstatus)}>
          {params.row.holdstatus}
        </Button>
      );
    }

    if (params.row.status === "Paid") {
      return (
        <Button color={getPayrollStatusColor(params.row.status)}>
          {params.row.status}
        </Button>
      );
    }

    return (
      <Select
        value={status}
        onChange={handleStatusChange}
        displayEmpty
        fullWidth
        sx={{
          border: "none",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          color: `${getPayrollStatusColor(status)}.main`,
        }}
      >
        <MenuItem value="Pending" sx={{ color: "warning.main" }}>
          Pending
        </MenuItem>
        <MenuItem value="Process" sx={{ color: "info.main" }}>
          Process
        </MenuItem>
        <MenuItem value="Paid" sx={{ color: "success.main" }}>
          Paid
        </MenuItem>
      </Select>
    );
  };

  // for closing multi status change alert

  const multiHandleClose = (event, reason) => {
    setSelectedRows([]);
    if (reason === "clickaway") {
      return;
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      let res = await axios.post(getPayrollInputsApi, {
        companyemail,
        companyid,
        month: currentDate.format("MMMM"),
        year: currentDate.format("YYYY"),
      }, { withCredentials: true });
      // console.log(res,"res payroll data ")
      res.data && res.data.length > 0 ? setData(res.data) : setData([]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setAlertType("error");
      setOpen(true);
      setMsg("Error while getting payroll inputs");
      setLoading(false);
    }
  };

  const multiStatusChange = async (idarray, status) => {
    try {
      let res = await axios.post(multiStatusPayrollInputsAPI, {
        idarray,
        status,
      }, { withCredentials: true });
      if (res.data.message) {
        setAlertType("success");
        setOpen(true);
        setMsg("Status Changed...");
        multiHandleClose();
        getData();
      }
    } catch (error) {
      console.log(error);
      setOpen(true);
      setAlertType("error");
      setMsg(error.response.data.message || "Something went wrong");
      // multiHandleClose()
    }
  };

  const [viewdialogOpen, setViewDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setViewDialogOpen(true);
  };

  const handleViewClose = () => {
    setViewDialogOpen(false);
    setSelectedRow({});
  };

  useEffect(() => {
    getData();
  }, [currentDate]);

  useEffect(() => {
    const filteredRows = data
      .filter((row) =>
        row.name.toLowerCase().includes(searchKeyword.toLowerCase())
      )
      .filter(
        (row) =>
          row.month === currentDate.format("MMMM") &&
          row.year === currentDate.format("YYYY")
      );
    setFilteredData(filteredRows);
    setPaidPayrollData(filteredRows.filter((row) => row.status == "Paid"));

    // Clone the currentDate before subtracting a month
    const prevDate = currentDate.clone().subtract(1, "month");

    const totalPrevPaidPayroll = data
      .filter(
        (row) =>
          row.month === prevDate.format("MMMM") &&
          row.year === prevDate.format("YYYY") &&
          row.status === "Paid"
      )
      .reduce((acc, curr) => acc + Number(curr.totalgross), 0);

    const totalCurrentHoldPayroll = data
      .filter(
        (row) =>
          row.month == currentDate.format("MMMM") &&
          row.year === currentDate.format("YYYY") &&
          row.holdstatus == "hold"
      )
      .reduce((acc, curr) => acc + Number(curr.totalgross), 0);

    const totalNetCurrPayroll = data
      .filter(
        (row) =>
          row.month == currentDate.format("MMMM") &&
          row.year === currentDate.format("YYYY")
      )
      .reduce((acc, curr) => acc + Number(curr.totalgross), 0);

    setCurrHoldPayrolls(totalCurrentHoldPayroll);
    setPrevPaidPayrolls(totalPrevPaidPayroll);
    setCurrNetPayrolls(totalNetCurrPayroll);
  }, [searchKeyword, data, currentDate]);

  const columns = [
    { field: 'employeeid', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'department', headerName: 'Department', flex: 1 },
    { field: 'position', headerName: 'Position', flex: 1 },
    {
      field: 'earning',
      headerName: 'Total Earning',
      flex: 1,
      renderCell: (params) => (
        <Box>{Number(params.row.earning || 0).toFixed(2)}</Box>
      ),
    },
    { field: 'deduction', headerName: 'Total Deduction', flex: 1 },
    { field: 'lop', headerName: 'LOP', flex: 1 },
    { field: "fandf", headerName: "F&F", flex: 1 },
    {
      field: 'totalgross',
      headerName: 'Net Total',
      flex: 1,
    },
    {
      field: 'payslip',
      headerName: 'Payslip',
      flex: 1,
      renderCell: (params) =>
        params.row.payslip ? (
          <IconButton
            onClick={(event) => {
              event.stopPropagation(); // Stop event propagation to prevent row click event
              handleJDClick(params.row);
            }}
          >
            <FaRegFilePowerpoint />
          </IconButton>
        ) : null,
      cellClassName: 'MuiDataGrid-cell--withRenderer',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: StatusCellRenderer,
    },
  ];

  //for multi status chnage
  const [selectedRows, setSelectedRows] = React.useState([]);
  const handleSelectionChange = (newSelection) => {
    const validSelection = newSelection.filter((id) => {
      const row = filteredData.find((r) => r.id === id);
      return row && row.holdstatus !== "hold" && row.status !== "Paid";
    });
    setSelectedRows(validSelection);
  };

  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      const validSelection = filteredData
        .filter((row) => row.holdstatus !== "hold" && row.status !== "Paid")
        .map((row) => row.id);
      setSelectedRows(validSelection);
    } else {
      setSelectedRows([]);
    }
  };
  //for graph
  const [graphData, setGraphData] = useState([]);
  const [currentYearIndex, setCurrentYearIndex] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);

  useEffect(() => {
    const transformedData = transformData(data);
    setGraphData(transformedData);
  }, [data]);

  useEffect(() => {
    if (graphData.length) {
      const { monthSpent } = graphData[currentYearIndex];
      const total = monthSpent.reduce(
        (sum, month) => sum + month.totalEarning,
        0
      );
      setTotalEarnings(total);
    }
  }, [graphData, currentYearIndex]);

  const handleNextYear2 = () => {
    setCurrentYearIndex((prev) => (prev + 1) % graphData.length);
  };

  const handlePrevYear = () => {
    setCurrentYearIndex((prev) =>
      prev === 0 ? graphData.length - 1 : prev - 1
    );
  };

  // if (!graphData.length) return null;

  const { year, monthSpent } = graphData[currentYearIndex] || {};

  const handleJDClick = (row) => {
    // console.log(row, "clicked row");

    // Ensure the URL is properly formatted
    let payslipURL = row.payslip;
    if (!payslipURL.startsWith("http://") && !payslipURL.startsWith("https://")) {
      // Assuming the relative URL should be prefixed with "https://"
      payslipURL = "https://" + payslipURL;
    }

    // Create a temporary link element for downloading
    const link = document.createElement("a");
    link.href = payslipURL;
    link.download = payslipURL.split("/").pop() || "Payslip_File.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // console.log(graphData, "graphData");
  //   console.log(PrevPaidPayrolls, "prevpaidrolls");
  //   console.log(CurrHoldPayrolls, "hold");
  //  console.log(CurrNetPayrolls, "honet currentd");

  // console.log(data, "all data");
  return (
    <>
      <Box className="chart">
        <Accordion >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          ><Typography variant="h6" >Payroll Chart</Typography></AccordionSummary>
          <AccordionDetails
            sx={{ backgroundColor: "#F4FAFF", borderRadius: 2, p: 1 }}
          >
            <Grid container justifyContent={"space-between"} gap={0.1}>
              <Grid
                item
                md={9}
                lg={9}
                sm={12}
                xs={12}
                bgcolor={"white"}
                p={2}
                borderRadius={2}
                className="commonShadow"
              >
                <Typography variant="h6" mb={2}>
                  Payroll
                </Typography>
                <Typography variant="h4" color="primary" mb={3}>
                  ₹ {totalEarnings.toLocaleString()}
                </Typography>
                <Box position="relative" mb={3}>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={monthSpent}>
                      <XAxis
                        dataKey="month"
                        padding={{ left: 30, right: 30 }}
                      />
                      <Tooltip />
                      <Line
                        type="monotone"
                        dataKey="totalgross"
                        stroke="#007bff"
                      />
                      <Line
                        type="monotone"
                        dataKey="totaldeduction"
                        stroke="#ff7300"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IconButton onClick={handlePrevYear}>
                    <NavigateBeforeIcon />
                  </IconButton>
                  <Typography variant="body1" mx={2}>
                    {year}
                  </Typography>
                  <IconButton onClick={handleNextYear2}>
                    <NavigateNextIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item height={"100%"} md={2.9} lg={2.9} sm={12} xs={12}>
                <SalaryCards
                  CurrHoldPayrolls={CurrHoldPayrolls}
                  CurrNetPayrolls={CurrNetPayrolls}
                  PrevPaidPayrolls={PrevPaidPayrolls}
                  currentDate={currentDate}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        p={2}
      >
        <Box display="flex" alignItems="center" gap={2} flexWrap={"wrap"}>
          <Typography variant="h6">Payroll</Typography>
          {/* radio buttons  */}
          <Box>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="by"
              value={checkBoxValue}
              onChange={(e) => setCheckBoxValue(e.target.value)}
            >
              <FormControlLabel
                value="By System"
                control={
                  <Radio
                    sx={{
                      color:
                        checkBoxValue === "By System" ? "#37668F" : "#98A2B3",
                      "&.Mui-checked": {
                        color: "#37668F",
                      },
                    }}
                  />
                }
                label="By System"
                sx={{
                  color: checkBoxValue === "By System" ? "#37668F" : "#98A2B3",
                }}
              />
              <FormControlLabel
                value="Via Import"
                control={
                  <Radio
                    sx={{
                      color:
                        checkBoxValue === "Via Import" ? "#37668F" : "#98A2B3",
                      "&.Mui-checked": {
                        color: "#37668F",
                      },
                    }}
                  />
                }
                label="Via Import"
                sx={{
                  color: checkBoxValue === "Via Import" ? "#37668F" : "#98A2B3",
                }}
              />
            </RadioGroup>
          </Box>

          {/* Month Navigation */}
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            border={"1px solid #0B4A6F"}
            borderRadius={2}
            flexWrap={"wrap"}
          >
            <IconButton onClick={handlePreviousMonth}>
              <NavigateBeforeIcon />
            </IconButton>
            <Typography variant="body1" component="span" color={"#0B4A6F"}>
              {currentDate.format("MMMM")}
            </Typography>
            <IconButton onClick={handleNextMonth}>
              <NavigateNextIcon />
            </IconButton>
          </Box>

          {/* Year Navigation */}
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            border={"1px solid #0B4A6F"}
            borderRadius={2}
            flexWrap={"wrap"}
          >
            <IconButton onClick={handlePreviousYear}>
              <NavigateBeforeIcon />
            </IconButton>
            <Typography variant="body1" component="span" color={"#0B4A6F"}>
              {currentDate.year()}
            </Typography>
            <IconButton onClick={handleNextYear}>
              <NavigateNextIcon />
            </IconButton>
          </Box>
        </Box>

        {/* Search Field */}
        <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
          <TextField
            placeholder="Search Employee..."
            variant="outlined"
            size="small"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            InputProps={{
              startAdornment: (
                <Box display="flex" alignItems="center" gap={1}>
                  <Search />
                </Box>
              ),
            }}
          />

          {paidPayrollData.length > 0 && (
            <SalarySlipComponent
              listData={paidPayrollData}
              data={data}
              setData={setData}
              currentDate={currentDate}
            />
          )}


          {checkBoxValue == "Via Import" && (
            <>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleFileChange}
              />
              <Button
                className="cancelBtn"
                variant="outlined"
                startIcon={<FiDownload />}
                onClick={downloadSample}
              >
                Sample
              </Button>
              <Button
                startIcon={<ExitToAppIcon />}
                className="saveBtn"
                onClick={handleUploadBtnClick}
              >
                Import File
              </Button>
            </>
          )}
        </Box>
      </Box>

      {/* DataGrid */}
      <Box my={1}>
        <Paper style={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={loading ? [] : filteredData}
            columns={columns}
            checkboxSelection

            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 8 },
              },
            }}
            pageSizeOptions={[8, 16, 24, 32, filteredData.length]}
            autoHeight
            components={{
              LoadingOverlay: Loader,
            }}
            loading={loading}
            onRowSelectionModelChange={handleSelectionChange}
            rowSelectionModel={selectedRows}
            onRowClick={handleRowClick}
            componentsProps={{
              baseCheckbox: {
                onClick: (event) => event.stopPropagation(),
              },
              header: {
                checkboxSelection: true,
                onHeaderCheckboxClick: handleHeaderCheckboxChange,
              },
            }}
          />
        </Paper>
      </Box>
      <ViewSalary
        handleViewClose={handleViewClose}
        selectedRow={selectedRow}
        viewdialogOpen={viewdialogOpen}
      />
      <MultiStatusChangePopUp
        selectedRows={selectedRows}
        multiHandleClose={multiHandleClose}
        multiStatusChange={multiStatusChange}
      />
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default Salary;
