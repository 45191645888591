import { Box, Button, Divider, IconButton, Paper, Stack, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Loader from '../../../../Constants/Loader';
import AlertPopUp from '../../../../Constants/AlertPopUp';
import { useDispatch, useSelector } from 'react-redux';
import { allproject, commondelete } from '../../../../redux/actions/actions.master/taskManagement.actions';
import { reloadPage } from '../../../../redux/actions/action.mobile/load.action';
import _ from 'lodash';
import { AuthContext } from '../../../../Context/AuthContextProvider';
import { allUsers } from '../../../../redux/actions/actions.master/user.actions';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { projectDeleteApi } from '../../../../Constants/api';
import ProjectDrawer from './ProjectDrawer';

function ListProject() {
    //context
    const { authContext } = useContext(AuthContext);
    const { userid, username, userrole, email, userphone, companyname, clientimage, companyid, companyemail } = authContext.userData;

    //redux
    const dispatch = useDispatch();
    const { allProjectData } = useSelector((state) => state.projectReducer || {});
    const { allUserData } = useSelector((state) => state.userReducer);
    const loadData = useSelector((state) => state.loadReducer);

    //State
    const [searchKeyword, setSearchKeyword] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [selectedRows, setSelectedRows] = useState([]);
    //for edit modal
    const [editItem, setEditItem] = useState({});

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        dispatch(reloadPage(true));
        setEditItem({});
        setDrawerOpen(false);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedRows(newSelection);
    };

    useEffect(() => {
        dispatch(reloadPage(true));
    }, []);

    useEffect(() => {
        if (loadData) {
            dispatch(allproject(setAlertType, setOpen, setMsg));
        }

        if (companyemail) {
            const body = {
                companyemail: companyemail,
                companyid: companyid
            }
            dispatch(allUsers(body, setOpen, setMsg, setAlertType));
        }

    }, [loadData, companyemail]);

    const debouncedSearch = useCallback(_.debounce(() => {
        setLoading(true);
        setTimeout(() => setLoading(false), 500);
    }, 300), []);

    const filteredData = useMemo(() => {
        if (allProjectData.length > 0) {
            debouncedSearch();
            const searchInObject = (obj, keyword) => {
                const searchStr = keyword.toString().toLowerCase().trim();
                return Object.values(obj).some(value => {
                    if (typeof value === 'object' && value !== null) {
                        return searchInObject(value, searchStr);
                    }
                    return value?.toString().toLowerCase().trim().includes(searchStr);
                });
            };
            return allProjectData?.filter(item => searchInObject(item, searchKeyword));
        } else {
            return [];
        }
    }, [searchKeyword, allProjectData]);

    const columns = [
        { field: "projectname", headerName: "Project Name", flex: 1 },
        { field: "createdby", headerName: "Created By", flex: 1 },
        { field: "assigneduser", headerName: "Assigned User", flex: 1 },
        { field: "startdate", headerName: "Start Date", flex: 1 },
        { field: "enddate", headerName: "End Date", flex: 1 },
        { field: "description", headerName: "Description", flex: 1 },
        {
            field: "action",
            headerName: "Actions",
            flex: 2,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Edit Project" placement="top-end">
                        <IconButton onClick={() => handleEditClick(params.row.id)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete Project" placement="top-end">
                        <IconButton
                            onClick={() =>
                                dispatch(
                                    commondelete(
                                        projectDeleteApi,
                                        [params.row.id],
                                        setAlertType,
                                        setOpen,
                                        setMsg,
                                    )
                                )
                            }
                        >
                            <DeleteOutlineIcon sx={{ color: "red" }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
            cellClassName: "MuiDataGrid-cell--withRenderer",
        },
    ];

    const rows = filteredData.map((item) => {
        const assignedUserNames = item.assigneduser
            .map((userId) => {
                const user = allUserData.find((u) => u.id === userId);
                return user ? user.username : null;
            })
            .filter((name) => name !== null);

        // console.log("assignedUserNames", assignedUserNames)

        return {
            id: item.id,
            projectname: item.projectname,
            createdby: item.createdby,
            startdate: item.startdate,
            enddate: item.enddate,
            description: item.description,
            assigneduser: assignedUserNames.length > 0 ? assignedUserNames.join(", ") : "Unassigned",
        };
    });

    const handleEditClick = (id) => {
        const editdata = allProjectData.length > 0 && allProjectData.find(item => item.id === id);
        setEditItem(editdata);
        handleDrawerOpen();
    }

    const handleDelete = () => {
        dispatch(
            commondelete(
                projectDeleteApi,
                selectedRows,
                setAlertType,
                setOpen,
                setMsg,
            )
        );
    }

    return (
        <>
            <Box borderRadius={2} my={2} className="commonShadow">
                <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                    <Typography
                        variant={isSmallScreen ? "h6" : "h5"}
                        className="master-heading"
                        p={2}
                    >
                        List of All Projects
                    </Typography>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        gap={"1rem"}
                        // justifyContent={"flex-end"}
                        p={1}
                    >
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            value={searchKeyword}
                            onChange={(e) => setSearchKeyword(e.target.value)}
                            style={{ marginRight: "16px" }}
                        />
                        <Button className="saveBtn" onClick={handleDrawerOpen}>
                            Add
                        </Button>

                        {selectedRows.length > 1 && (
                            <div>
                                <Tooltip title="Delete All" placement="top-end">
                                    <DeleteSweepIcon
                                        sx={{ color: "red", cursor: "pointer" }}
                                        onClick={handleDelete}
                                    />
                                </Tooltip>
                            </div>
                        )}
                    </Box>
                </Stack>
                <Divider />
                <Box my={1}>
                    <Paper style={{ height: "auto", width: "100%" }}>
                        <DataGrid
                            rows={loadData ? [] : rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15, 20, rows.length]}
                            checkboxSelection
                            getRowId={(row) => row.id}
                            autoHeight
                            components={{
                                LoadingOverlay: Loader,
                            }}
                            loading={loading}
                            noRowsOverlay
                            // onRowClick={handleRowClick}
                            onRowSelectionModelChange={(newSelection) => {
                                handleSelectionModelChange(newSelection);
                            }}
                        />
                    </Paper>
                </Box>
            </Box>
            <ProjectDrawer
                drawerOpen={drawerOpen}
                handleDrawerClose={handleDrawerClose}
                isEditItem={Object.keys(editItem).length > 0}
                isSmallScreen={isSmallScreen}
                editItem={editItem}
            />
            {/* {editItemId &&
                <EditProject
                    drawerOpen={drawerOpen}
                    handleDrawerClose={handleDrawerClose}
                />} */}
            <AlertPopUp
                open={open}
                msg={msg}
                handleClose={handleClose}
                type={alertType}
            />
        </>
    )
}

export default ListProject;