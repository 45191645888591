import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllowanceApi,
  hrmsEmpRegAadharUpload,
  hrmsEmpRegAppraisalUpload,
  hrmsEmpRegBankStatementUpload,
  hrmsEmpRegBonafideUpload,
  hrmsEmpRegBondUpload,
  hrmsEmpRegExpCertificateUpload,
  hrmsEmpRegNDAUpload,
  hrmsEmpRegPanUpload,
  hrmsEmpRegPassportUpload,
  hrmsEmpRegPayslipUpload,
  hrmsEmpRegRatingCardUpload,
  hrmsEmployeeRegistrationGetByID,
} from "../../../../Constants/api";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { getEmpPosition } from "../../../../redux/actions/actions.master/empPosition.actions";
import {
  getEmpReg,
  updateEmpReg,
} from "../../../../redux/actions/actions.hrms/empRegistration.actions";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import { Country, State, City } from "country-state-city";
import { getAttendanceShift } from "../../../../redux/actions/actions.master/attendanceShift.actions";
import { getGeneralValue } from "../../../../redux/actions/actions.master/value.actions";
import dayjs from "dayjs";
import {
  extractYear,
  getFileNameFromUrl,
  truncateText,
} from "../../../../Constants/constants";
import { formatDate } from "../../../../Constants/dateFormat";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const allCountries = Country.getAllCountries();
const allStates = State.getStatesOfCountry("in");

const boxStyle = {
  margin: "10px",
  padding: "10px",
  borderRadius: "5px",
  backgroundColor: "#f9fafb",
};
const typoHeading = {
  color: "black",
  fontSize: "18px",
};
const typoValue = {
  color: "grey",
  fontSize: "16px",
};
const empInfoBox = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
};
const saveBtnBox = {
  mt: "auto",
  display: "flex",
  justifyContent: "flex-end",
  gap: 2,
  padding: 1,
};
function stringAvatar(name) {
  // console.log(name, "undefine wala eror")
  if (!name) {
    return;
  }
  return {
    sx: {
      bgcolor: "grey",
      width: "90px",
      height: "90px",
    },
    children: name
      ? name.split(" ").length > 1
        ? `${name.split(" ")[0][0]}${name.split(" ")[0][1]}`
        : name.split(" ")[0][0]
      : "",
  };
}

function stringAvatar2(name) {
  if (!name) {
    return;
  }
  return {
    sx: {
      bgcolor: "grey",
      width: "50px",
      height: "50px",
    },
    children: name
      ? name.split(" ").length > 0 &&
      `${name.split(" ")[0][0]}${name.split(" ")[0][1]}`
      : "",
  };
}

const ViewEditEmployee = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  let companyid = companyInfo.companyid ? companyInfo.companyid : "";
  let companyemail = companyInfo.companyemail ? companyInfo.companyemail : "";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { shiftData } = useSelector((state) => state.attendanceShiftReducer);

  const { id } = useParams();
  const [data, setData] = useState({});
  const [allEmp, setAllEmp] = useState([]);
  const [editableED, setEditableED] = useState(false); //Employee Details
  const [editablePD, setEditablePD] = useState(false); // Personal Details
  const [editablePA, setEditablePA] = useState(false); // Permanent Address
  const [editableEC, setEditableEC] = useState(false); // Emergency Contact
  const [editableDV, setEditableDV] = useState(false); // Document Verification
  const [editableAST, setEditableAST] = useState(false); // Assets
  const [editableBnf, setEditableBnf] = useState(false); // Employee Documents // Bonafide
  const [editableAprsl, setEditableAprsl] = useState(false); // Employee Documents // Appraisal
  const [editableRC, setEditableRC] = useState(false); // Employee Documents // Reccomendation Certificate
  const [editableBond, setEditableBond] = useState(false); // Employee Documents // Bond
  const [editableNDA, setEditableNDA] = useState(false); // Employee Documents // NDA
  const [editablePAN, setEditablePAN] = useState(false); // Employee Documents // PAN
  const [editableADH, setEditableADH] = useState(false); // Employee Documents // Aadhaar
  const [editablePP, setEditablePP] = useState(false); // Employee Documents // Passport
  const [editableEXPC, setEditableEXPC] = useState(false); // Employee Documents // Experience Certificate
  const [editablePS, setEditablePS] = useState(false); // Employee Documents // Payslip
  const [editableBankS, setEditableBankS] = useState(false); // Employee Documents // Bank Statement
  const [states, setStates] = useState(allStates);
  const [states1, setStates1] = useState([]);
  const [ecStates, setECStates] = useState([]);
  const [paStates, setPAStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [ecCities, setECCities] = useState([]);
  const [paCities, setPACities] = useState([]);
  const [editableEdu, setEditableEdu] = useState(false); // Education Details

  const [dropDowns, setDropDowns] = useState([]);
  const [masterPayrollStructure, setMasterPayrollStructure] = useState([]);

  // console.log(cities, "cities");
  //for success alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //for inplace error input
  const [formData, setFormData] = useState({
    email: "",
    ecemail: "",
    personalemail: "",
    uan: "",
    esic: "",
    accountno: "",
    ifsc: "",
    pincode: "",
    ecpincode: "",
    papincode: "",
    contactno: "",
    eccontactno: "",
    panno: "",
    aadharno: "",
  });

  const [errors, setErrors] = useState({
    email: false,
    ecemail: false,
    personalemail: false,
    uan: false,
    esic: false,
    accountno: false,
    ifsc: false,
    pincode: false,
    ecpincode: false,
    papincode: false,
    contactno: false,
    eccontactno: false,
    panno: false,
    aadharno: false,
  });

  // Regex patterns for validation
  const regexPatterns = {
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    ecemail: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    personalemail: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    esic: /^[0-9]{10}$/,
    uan: /^[0-9]{12}$/,
    accountno: /^[0-9]{9,18}$/,
    ifsc: /^[A-Z]{4}0[A-Z0-9]{6}$/,
    pincode: /^[1-9][0-9]{5}$/,
    ecpincode: /^[1-9][0-9]{5}$/,
    papincode: /^[1-9][0-9]{5}$/,
    contactno: /^[6-9]\d{9}$/,
    eccontactno: /^[6-9]\d{9}$/,
    panno: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    aadharno: /^[0-9]{12}$/,
  };

  const errorMessages = {
    email:
      "Invalid email address. Please enter a valid email in the format example@domain.com.",
    ecemail:
      "Invalid email address. Please enter a valid email in the format example@domain.com.",
    personalemail:
      "Invalid email address. Please enter a valid email in the format example@domain.com.",
    esic: "Invalid ESIC number. It should be a 10-digit number.",
    uan: "Invalid UAN number. It should be a 12-digit number.",
    accountno: "Invalid bank account number. It should be 9 to 18 digits long.",
    ifsc: "Invalid IFSC code. IFSC should be in the format ABCD0123456.",
    pincode: "Invalid pincode. Pincode should be a 6-digit number.",
    ecpincode: "Invalid pincode. Pincode should be a 6-digit number.",
    papincode: "Invalid pincode. Pincode should be a 6-digit number.",
    contactno:
      "Invalid phone number. It should be a 10-digit number starting with 6-9.",
    eccontactno:
      "Invalid phone number. It should be a 10-digit number starting with 6-9.",
    panno: "Invalid PAN number. PAN should be in the format ABCDE1234F.",
    aadharno: "Invalid UAN number. It should be a 12-digit number.",
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Only validate ESIC if it's not empty (since it's optional)
    if (regexPatterns[name].test(value)) {
      setErrors({
        ...errors,
        [name]: false,
      });
    } else {
      setErrors({
        ...errors,
        [name]: true,
      });
    }
  };

  const isFormValid = () => {
    // Check if there are any errors or empty fields
    return (
      Object.values(errors).every((error) => !error) &&
      Object.values(formData).every((field) => field !== "")
    );
  };

  const handleClickEdit = (name) => {
    if (name == "Education Details") {
      setEditableEdu(true);
      setEducationDetails({
        education:
          (data.educationarray && data.educationarray[0]?.education) || "",
        from: (data.educationarray && data.educationarray[0]?.from) || "",
        to: (data.educationarray && data.educationarray[0]?.to) || "",
        institute:
          (data.educationarray && data.educationarray[0]?.institute) || "",
        certificate:
          (data.educationarray && data.educationarray[0]?.certificate) || "",
      });
    } else if (name == "Assets") {
      setEditableAST(true);
    } else if (name == "Document Verification") {
      setEditableDV(true);
    } else if (name == "Emergency Contact") {
      setEditableEC(true);
    } else if (name == "Permanent Address") {
      setEditablePA(true);
    } else if (name == "Personal Details") {
      setEditablePD(true);
    } else if (name == "Employee Details") {
      setEditableED(true);
    }
  };
  const handleClickUploadEdit = (name) => {
    if (name == "Bonafide") {
      setEditableBnf(true);
    } else if (name == "Appraisal") {
      setEditableAprsl(true);
    } else if (name == "Reccomendation Certificate") {
      setEditableRC(true);
    } else if (name == "Bond") {
      setEditableBond(true);
    } else if (name == "NDA") {
      setEditableNDA(true);
    } else if (name == "PAN") {
      setEditablePAN(true);
    } else if (name == "Aadhaar") {
      setEditableADH(true);
    } else if (name == "Passport") {
      setEditablePP(true);
    } else if (name == "Experience Certificate") {
      setEditableEXPC(true);
    } else if (name == "Payslip") {
      setEditablePS(true);
    } else if (name == "Bank Statement") {
      setEditableBankS(true);
    }
  };

  const handleEdit = async () => {
    if (editableDV && data.verifystatus == "Completed" && !data.verifydate) {
      setOpen(true);
      setAlertType("error");
      setMsg("Verification date required");
      return;
    }
    const obj = { ...data, ...formData };
    // console.log("obj", obj);
    dispatch(
      updateEmpReg(
        obj,
        setOpen,
        setMsg,
        setAlertType,
        setEditableAST,
        setEditableEC,
        setEditableDV,
        setEditablePA,
        setEditablePD,
        setEditableED,
        setEditableEdu, // Add this
        setEditableBnf,
        setEditableAprsl,
        setEditableRC,
        setEditableBond,
        setEditableNDA,
        setEditablePAN,
        setEditableADH,
        setEditablePP,
        setEditableEXPC,
        setEditablePS,
        setEditableBankS
      )
    );
    setEditableBankS(false);
  };
  const handleUpload = async (e, name) => {
    const files = e.target.files;
    if (files.length > 0) {
      // Process the files here
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      let endpoint;
      if (name == "nda") {
        endpoint = hrmsEmpRegNDAUpload;
      } else if (name == "bonafide") {
        endpoint = hrmsEmpRegBonafideUpload;
      } else if (name == "bond") {
        endpoint = hrmsEmpRegBondUpload;
      } else if (name == "appraisal") {
        endpoint = hrmsEmpRegAppraisalUpload;
      } else if (name == "ratingcard") {
        endpoint = hrmsEmpRegRatingCardUpload;
      } else if (name == "expcertificate") {
        endpoint = hrmsEmpRegExpCertificateUpload;
      } else if (name == "passport") {
        endpoint = hrmsEmpRegPassportUpload;
      } else if (name == "payslip") {
        endpoint = hrmsEmpRegPayslipUpload;
      } else if (name == "bankstatement") {
        endpoint = hrmsEmpRegBankStatementUpload;
      } else if (name == "aadhar") {
        endpoint = hrmsEmpRegAadharUpload;
      } else if (name == "pan") {
        endpoint = hrmsEmpRegPanUpload;
      } else {
        console.error("No endpoint found for the given name:", name);
        return;
      }

      const res = await axios.post(
        endpoint,
        formData,
        { withCredentials: true },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (res.data.file) {
        const fileurl = res.data.file;
        setData((prevData) => ({
          ...prevData,
          [name]: fileurl,
        }));
        setAlertType("success");
        setOpen(true);
        setMsg("Document Uploaded");
      } else {
        // console.log("choose file");
        setOpen(true);
        setAlertType("error");
        setMsg("Choose a file to upload..");
      }
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleEducationChange = (e, index, field) => {
    const updatedEducationArray = [...data.educationarray];
    updatedEducationArray[index] = {
      ...updatedEducationArray[index],
      [field]: e.target.value,
    };
    setData({
      ...data,
      educationarray: updatedEducationArray,
    });
  };

  const getDropdowns = async () => {
    try {
      let res = await axios.post(
        `https://hrms.vliv.app/employee/position/get`,
        {
          companyid,
          companyemail,
        },
        { withCredentials: true }
      );
      res.data && res.data.length > 0
        ? setDropDowns(
          res.data.filter((ele) => ele.status.toLowerCase() == "active")
        )
        : setDropDowns([]);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getAllEmp = async () => {
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/employee/get",
        {
          companyid,
          companyemail,
        },
        { withCredentials: true }
      );
      res.data && res.data.length ? setAllEmp(res.data) : setAllEmp([]);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getEmployeById = async () => {
    axios
      .post(hrmsEmployeeRegistrationGetByID, { id }, { withCredentials: true })
      .then((res) => {
        if (
          res.data &&
          res.data.educationarray &&
          res.data.educationarray.length > 0
        ) {
          setData((prev) => ({
            ...prev,
            ...res.data,
          }));
        } else {
          setData((prev) => ({
            ...prev,
            ...res.data,
            educationarray: [
              {
                educationname: "10th",
                educationtype: "Secondary",
                marksheet: "",
                percentage: "",
                passoutyear: "",
              },
              {
                educationname: "12th",
                educationtype: "inter",
                marksheet: "",
                percentage: "",
                passoutyear: "",
              },
              {
                educationname: "",
                educationtype: "degree",
                marksheet: "",
                percentage: "",
                passoutyear: "",
              },
              {
                educationname: "",
                educationtype: "masterdegree",
                marksheet: "",
                percentage: "",
                passoutyear: "",
              },
            ],
          }));
        }
        setFormData((prev) => ({
          ...prev,
          aadharno: res.data.aadharno || "",
          accountno: res.data.accountno || "",
          contactno: res.data.contactno || "",
          eccontactno: res.data.eccontactno || "",
          ecemail: res.data.ecemail || "",
          ecpincode: res.data.ecpincode || "",
          email: res.data.email || "",
          esic: res.data.esic || "",
          ifsc: res.data.ifsc || "",
          panno: res.data.panno || "",
          papincode: res.data.papincode || "",
          personalemail: res.data.personalemail || "",
          pincode: res.data.pincode || "",
          uan: res.data.uan || "",
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmployeById();
    getDropdowns();
    getAllEmp();
  }, []);

  useEffect(() => {
    dispatch(
      getEmpPosition({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
    dispatch(
      getEmpReg({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
    dispatch(
      getAttendanceShift({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
    dispatch(
      getGeneralValue({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  // For educational details start
  const [educationDetails, setEducationDetails] = useState({
    education: "",
    from: "",
    to: "",
    institute: "",
    certificate: "",
  });

  // const handleEducationChange = (e) => {
  //   setEducationDetails({
  //     ...educationDetails,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const saveEducationDetails = async () => {
    // const updatedEducationArray = [educationDetails];
    // setData({
    //   ...data,
    //   educationarray: updatedEducationArray,
    // });

    dispatch(
      updateEmpReg(
        {
          ...data,
          // educationarray: updatedEducationArray,
        },
        setOpen,
        setMsg,
        setAlertType,
        setEditableAST,
        setEditableEC,
        setEditableDV,
        setEditablePA,
        setEditablePD,
        setEditableED,
        setEditableEdu, // Add this
        setEditableBnf,
        setEditableAprsl,
        setEditableRC,
        setEditableBond,
        setEditableNDA,
        setEditablePAN,
        setEditableADH,
        setEditablePP,
        setEditableEXPC,
        setEditablePS,
        setEditableBankS
      )
    );

    setEditableEdu(false);
  };

  const cancelEducationEdit = () => {
    setEditableEdu(false);
  };

  // For educational details end

  // For country and state dropdown start
  //  ---------------For prmanent address ---------------
  const handleCountryChange = (e) => {
    const { value } = e.target;
    const selectedCountry = allCountries.find(
      (country) => country.name === value
    );
    const updatedStates = selectedCountry
      ? State.getStatesOfCountry(selectedCountry.isoCode)
      : [];
    setStates(updatedStates);
    setCities([]); // Reset cities when country changes
    setData({ ...data, country: value, state: "" }); // Reset state when country changes
  };

  const handleStateChange = (e) => {
    const { value } = e.target;
    const selectedState = states.find((state) => state.name === value);
    const updatedCities = selectedState
      ? City.getCitiesOfState(selectedState.countryCode, selectedState.isoCode)
      : [];
    setCities(updatedCities);
    setData({ ...data, state: value, city: "" }); // Reset city when state changes
  };

  // ------------------------For emergancy contact -------------------------
  const handleEcCountryChange = (e) => {
    const { value } = e.target;
    const selectedCountry = allCountries.find(
      (country) => country.name === value
    );
    const updatedStates = selectedCountry
      ? State.getStatesOfCountry(selectedCountry.isoCode)
      : [];
    setECStates(updatedStates);
    setECCities([]); // Reset cities when country changes
    setData({ ...data, eccountry: value, ecstate: "" }); // Reset state when country changes
  };

  const handleEcStateChange = (e) => {
    const { value } = e.target;
    const selectedState = ecStates.find((state) => state.name === value);
    const updatedCities = selectedState
      ? City.getCitiesOfState(selectedState.countryCode, selectedState.isoCode)
      : [];
    setECCities(updatedCities);
    setData({ ...data, ecstate: value, eccity: "" }); // Reset city when state changes
  };

  // -------------------- For permanent address ----------
  const handlePaCountryChange = (e) => {
    const { value } = e.target;
    const selectedCountry = allCountries.find(
      (country) => country.name === value
    );
    const updatedStates = selectedCountry
      ? State.getStatesOfCountry(selectedCountry.isoCode)
      : [];
    setPAStates(updatedStates);
    setPACities([]); // Reset cities when country changes
    setData({ ...data, pacountry: value, pastate: "" }); // Reset state when country changes
  };

  const handlePaStateChange = (e) => {
    const { value } = e.target;
    const selectedState = paStates.find((state) => state.name === value);
    const updatedCities = selectedState
      ? City.getCitiesOfState(selectedState.countryCode, selectedState.isoCode)
      : [];
    setPACities(updatedCities);
    setData({ ...data, pastate: value, pacity: "" }); // Reset city when state changes
  };

  //for getting all payrollstructures
  const getPayrollStructureData = async () => {
    try {
      const res = await axios.post(
        getAllowanceApi,
        {},
        { withCredentials: true }
      );
      setMasterPayrollStructure(res.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  //for disabling and clearing verification date according to verify status
  useEffect(() => {
    if (data.verifystatus != "Completed") {
      setData((prev) => ({ ...prev, verifydate: "" }));
    }
  }, [data.verifystatus]);

  //for calculation of confirmation date

  useEffect(() => {
    getPayrollStructureData();
  }, []);

  // console.log(data, "data....123 ")
  // console.log(states,"states");
  // console.log(paStates,"permanent address states");
  // console.log(ecStates,"emergency contact states");

  useEffect(() => {
    if (data.country) {
      const selectedCountry = allCountries.find(
        (country) => country.name == data.country
      );

      const updatedStates = selectedCountry
        ? State.getStatesOfCountry(selectedCountry.isoCode)
        : [];
      const updatedCities = selectedCountry
        ? City.getCitiesOfCountry(selectedCountry.isoCode)
        : [];
      setStates(updatedStates);
      setCities(updatedCities);
    }

    if (data.pacountry) {
      const selectedCountry = allCountries.find(
        (country) => country.name == data.pacountry
      );

      const updatedStates = selectedCountry
        ? State.getStatesOfCountry(selectedCountry.isoCode)
        : [];
      const updatedCities = selectedCountry
        ? City.getCitiesOfCountry(selectedCountry.isoCode)
        : [];
      setPAStates(updatedStates);
      setPACities(updatedCities);
    }

    if (data.eccountry) {
      const selectedCountry = allCountries.find(
        (country) => country.name == data.eccountry
      );

      const updatedStates = selectedCountry
        ? State.getStatesOfCountry(selectedCountry.isoCode)
        : [];
      const updatedCities = selectedCountry
        ? City.getCitiesOfCountry(selectedCountry.isoCode)
        : [];
      setECStates(updatedStates);
      setECCities(updatedCities);
    }
  }, [data.country, data.pacountry, data.eccountry]);

  return (
    <>
      <Box>
        {/*----------------Heading Employee------------------*/}
        <Box sx={boxStyle}>
          <Button
            className="cancelBtn"
            variant="outlined"
            onClick={() => navigate(-1)}
            startIcon={<KeyboardBackspaceIcon />}
            margin="normal"
          >
            Back
          </Button>
        </Box>
        <Box sx={boxStyle}>
          <Stack direction="row" gap={5}>
            <Box display={"flex"} gap={2}>
              <Avatar {...stringAvatar(data && data.name)} />
              <Box>
                <Typography sx={typoHeading}>{data && data.name}</Typography>
                <Typography sx={typoValue}>Serial No.: {data && data.empid}</Typography>
                <Typography sx={typoValue}>{data && data.role}</Typography>
                <Typography sx={typoValue}>
                  {formData && formData.email}
                </Typography>
              </Box>
            </Box>
            <Box sx={empInfoBox}>
              <Typography sx={typoValue}>Company Employee ID</Typography>
              <Typography sx={typoHeading}>{data.companyemployeeid ? data.companyemployeeid : "--"}</Typography>
            </Box>
            <Box sx={empInfoBox}>
              <Typography sx={typoValue}>Contact Number</Typography>
              <Typography sx={typoHeading}>
                {" "}
                {formData && formData.contactno ? formData.contactno : "--"}
              </Typography>
            </Box>
            <Box sx={empInfoBox}>
              <Typography sx={typoValue}>Department</Typography>
              <Typography sx={typoHeading}>
                {data && data.department}
              </Typography>
            </Box>
            <Box sx={empInfoBox}>
              <Typography sx={typoValue}>Sub-Department</Typography>
              <Typography sx={typoHeading}>
                {data && data.subdepartment ? data.subdepartment : "--"}
              </Typography>
            </Box>
            <Box sx={empInfoBox}>
              <Typography sx={typoValue}>Employee Type</Typography>
              <Typography sx={typoHeading}>{data && data.emptype}</Typography>
            </Box>
            <Box display={"flex"} gap={2} alignItems={"flex-end"}>
              <Avatar {...stringAvatar2(data && data.reportmanager)} />
              <Box>
                <Typography sx={typoValue}>Reporting Manager</Typography>
                <Typography sx={typoHeading}>
                  {data && data.reportmanager}
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Box>

        {/*----------------Employee Details------------------*/}
        <Box sx={boxStyle}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography sx={typoHeading}>Employment Details</Typography>
            {!editableED && (
              <Button
                variant="outlined"
                endIcon={<EditIcon />}
                onClick={() => handleClickEdit("Employee Details")}
              >
                Edit
              </Button>
            )}
          </Box>
          <br />
          <Divider></Divider>
          <br />
          <Stack direction="row" justifyContent={"space-between"} spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Position</Typography>
                {editableED ? (
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={
                      dropDowns
                        .filter((dropd) => dropd.position === "position")
                        .map((elem) => elem.name) || []
                    }
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        sx={{ p: 0 }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    value={data.position}
                    onChange={(event, newValue) =>
                      handleChange({
                        target: { name: "position", value: newValue },
                      })
                    }
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.position ? data.position : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Level</Typography>
                {editableED ? (
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={
                      dropDowns
                        .filter((dropd) => dropd.position === "level")
                        .map((elem) => elem.name) || []
                    }
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        sx={{ p: 0 }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    value={data.level}
                    onChange={(event, newValue) =>
                      handleChange({
                        target: { name: "level", value: newValue },
                      })
                    }
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.level ? data.level : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Confirmation Date</Typography>
                {editableED ? (
                  <TextField
                    fullWidth
                    type="date"
                    className="formInputs"
                    placeholder="Enter Name"
                    value={data.confirmdate}
                    onChange={handleChange}
                    name="confirmdate"
                  // disabled
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.confirmdate
                      ? formatDate(data.confirmdate)
                      : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Experience</Typography>
                {editableED ? (
                  <TextField
                    fullWidth
                    className="formInputs"
                    placeholder="Experience in Month/Year"
                    value={data.experience}
                    onChange={handleChange}
                    name="experience"
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.experience ? data.experience : "--"}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={3}>
                <Typography sx={typoValue}>Profile</Typography>
                {editableED ? (
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={
                      dropDowns
                        .filter((dropd) => dropd.position === "profile")
                        .map((elem) => elem.name) || []
                    }
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        sx={{ p: 0 }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    value={data.profile}
                    onChange={(event, newValue) =>
                      handleChange({
                        target: { name: "profile", value: newValue },
                      })
                    }
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.profile ? data.profile : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Official Email</Typography>
                {editableED ? (
                  <TextField
                    fullWidth
                    className="formInputs"
                    value={formData.email}
                    onChange={handleInputChange}
                    error={errors.email}
                    helperText={errors.email && errorMessages.email}
                    name="email"
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {formData && formData.email ? formData.email : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Joined Date</Typography>
                {editableED ? (
                  <TextField
                    fullWidth
                    type="date"
                    className="formInputs"
                    value={data.joiningdate}
                    onChange={handleChange}
                    name="joiningdate"
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.joiningdate
                      ? formatDate(data.joiningdate)
                      : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Employee Type</Typography>
                {editableED ? (
                  <Autocomplete
                    // className="formInputs"
                    size="small"
                    fullWidth
                    options={
                      dropDowns
                        .filter((dropd) => dropd.position === "employeetype")
                        .map((elem) => elem.name) || []
                    }
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        sx={{ p: 0 }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    value={data.emptype}
                    onChange={(event, newValue) =>
                      handleChange({
                        target: { name: "emptype", value: newValue },
                      })
                    }
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.emptype ? data.emptype : "--"}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={3}>
                <Typography sx={typoValue}>Department</Typography>
                {editableED ? (
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={
                      dropDowns
                        .filter((dropd) => dropd.position === "department")
                        .map((elem) => elem.name) || []
                    }
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        sx={{ p: 0 }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    value={data.department}
                    onChange={(event, newValue) =>
                      handleChange({
                        target: { name: "department", value: newValue },
                      })
                    }
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.department ? data.department : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Attendance Scheme</Typography>
                {editableED ? (
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={
                      dropDowns
                        .filter(
                          (dropd) => dropd.position === "attendancescheme"
                        )
                        .map((elem) => elem.name) || []
                    }
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        sx={{ p: 0 }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    value={data.attendancescheme}
                    onChange={(event, newValue) =>
                      handleChange({
                        target: { name: "attendancescheme", value: newValue },
                      })
                    }
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.attendancescheme
                      ? data.attendancescheme
                      : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Status</Typography>
                {editableED ? (
                  <Select
                    fullWidth
                    className="formInputs"
                    inputProps={{
                      className: "selectInput",
                    }}
                    placeholder="Enter Name"
                    value={data.status}
                    onChange={handleChange}
                    name="status"
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="In-Active">In-Active</MenuItem>
                  </Select>
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.status ? data.status : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Source</Typography>
                {editableED ? (
                  <TextField
                    fullWidth
                    className="formInputs"
                    placeholder="Outsourced From"
                    value={data.source}
                    onChange={handleChange}
                    name="source"
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.source ? data.source : "--"}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={3}>
                <Typography sx={typoValue}>Payroll Structure</Typography>
                {editableED ? (
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={
                      masterPayrollStructure.map((elem) => elem.name) || []
                    }
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        sx={{ p: 0 }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    value={data.payroll}
                    onChange={(event, newValue) =>
                      handleChange({
                        target: { name: "payroll", value: newValue },
                      })
                    }
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.payroll ? data.payroll : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>CTC in LPA</Typography>
                {editableED ? (
                  <TextField
                    fullWidth
                    className="formInputs"
                    placeholder="Enter Amount"
                    value={data.ctc}
                    onChange={handleChange}
                    name="ctc"
                    type="number"
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.ctc ? data.ctc : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>EPF Applicable</Typography>
                {editableED ? (
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={["Yes", "No"]}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        sx={{ p: 0 }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    value={data.isepf === true ? "Yes" : "No"}
                    onChange={(event, newValue) =>
                      handleChange({
                        target: {
                          name: "isepf",
                          value: newValue === "Yes" ? true : false,
                        },
                      })
                    }
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.isepf ? "Yes" : "No"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>ESIC Applicable</Typography>
                {editableED ? (
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={["Yes", "No"]}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        sx={{ p: 0 }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    value={data.isesic === true ? "Yes" : "No"}
                    onChange={(event, newValue) =>
                      handleChange({
                        target: {
                          name: "isesic",
                          value: newValue === "Yes" ? true : false,
                        },
                      })
                    }
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.isesic ? "Yes" : "No"}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={3}>
                <Typography sx={typoValue}>UAN</Typography>
                {editableED ? (
                  <TextField
                    fullWidth
                    className="formInputs"
                    placeholder="Enter UAN"
                    value={formData.uan}
                    onChange={handleInputChange}
                    error={errors.uan}
                    helperText={errors.uan && errorMessages.uan}
                    name="uan"
                    type="number"
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {formData && formData.uan ? formData.uan : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>ESIC</Typography>
                {editableED ? (
                  <TextField
                    fullWidth
                    className="formInputs"
                    placeholder="Enter ESIC"
                    value={formData.esic}
                    onChange={handleInputChange}
                    error={errors.esic}
                    helperText={errors.esic && errorMessages.esic}
                    name="esic"
                    type="number"
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {formData && formData.esic ? formData.esic : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Bank Account Number</Typography>
                {editableED ? (
                  <TextField
                    fullWidth
                    className="formInputs"
                    placeholder="Enter Bank Account Number"
                    value={formData.accountno}
                    onChange={handleInputChange}
                    error={errors.accountno}
                    helperText={errors.accountno && errorMessages.accountno}
                    name="accountno"
                    type="number"
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {formData && formData.accountno ? formData.accountno : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>IFSC Code</Typography>
                {editableED ? (
                  <TextField
                    fullWidth
                    className="formInputs"
                    placeholder="Enter IFSC Code"
                    value={formData.ifsc}
                    onChange={handleInputChange}
                    error={errors.ifsc}
                    helperText={errors.ifsc && errorMessages.ifsc}
                    name="ifsc"
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {formData && formData.ifsc ? formData.ifsc : "--"}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={3}>
                <Typography sx={typoValue}>Sub-Department</Typography>
                {editableED ? (
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={
                      dropDowns
                        .filter((dropd) => dropd.position === "subdepartment")
                        .map((elem) => elem.name) || []
                    }
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        sx={{ p: 0 }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    value={data.subdepartment}
                    onChange={(event, newValue) =>
                      handleChange({
                        target: { name: "subdepartment", value: newValue },
                      })
                    }
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.subdepartment ? data.subdepartment : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>OT Applicable</Typography>
                {editableED ? (
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={["Yes", "No"]}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        sx={{ p: 0 }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    value={data.otapplicable}
                    onChange={(event, newValue) =>
                      handleChange({
                        target: { name: "otapplicable", value: newValue },
                      })
                    }
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.otapplicable ? data.otapplicable : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Probation Period</Typography>
                {editableED ? (
                  <TextField
                    fullWidth
                    className="formInputs"
                    placeholder="Input in Days"
                    value={data.probationperiod}
                    onChange={handleChange}
                    name="probationperiod"
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.probationperiod ? data.probationperiod : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Variable Pay</Typography>
                {editableED ? (
                  <TextField
                    fullWidth
                    className="formInputs"
                    placeholder="Enter Amount"
                    value={data.variablepay}
                    onChange={handleChange}
                    name="variablepay"
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.variablepay ? data.variablepay : "--"}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={3}>
                <Typography sx={typoValue}>Reporting Manager</Typography>
                {editableED ? (
                  <Select
                    fullWidth
                    className="formInputs"
                    inputProps={{
                      className: "selectInput",
                    }}
                    placeholder="Enter Name"
                    value={data.reportmanager}
                    onChange={handleChange}
                    name="reportmanager"
                  >
                    {allEmp
                      ?.filter(
                        (emp) =>
                          emp.role.toLowerCase() === "masteradmin" ||
                          emp.role.toLowerCase() === "manager"
                      )
                      .map((em) => (
                        <MenuItem value={em.name} key={em.id}>
                          {em.name}
                        </MenuItem>
                      ))}
                  </Select>
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.reportmanager ? data.reportmanager : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Branch</Typography>
                {editableED ? (
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={
                      dropDowns
                        .filter((dropd) => dropd.position === "branch")
                        .map((elem) => elem.name) || []
                    }
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        sx={{ p: 0 }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    value={data.branch}
                    onChange={(event, newValue) =>
                      handleChange({
                        target: { name: "branch", value: newValue },
                      })
                    }
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.branch ? data.branch : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Notice Period</Typography>
                {editableED ? (
                  <TextField
                    fullWidth
                    className="formInputs"
                    placeholder="Input in Month"
                    value={data.noticeperiod}
                    onChange={handleChange}
                    name="noticeperiod"
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.noticeperiod ? data.noticeperiod : "--"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={typoValue}>Shift Type</Typography>
                {editableED ? (
                  <Select
                    fullWidth
                    className="formInputs"
                    inputProps={{
                      className: "selectInput",
                    }}
                    placeholder="Enter Name"
                    value={data.swifttype}
                    onChange={handleChange}
                    name="swifttype"
                  >
                    {shiftData.length > 0 &&
                      shiftData.map((elem, index) => (
                        <MenuItem key={index} value={elem.shiftname}>
                          {elem.shiftname}
                        </MenuItem>
                      ))}
                  </Select>
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.swifttype ? data.swifttype : "--"}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={3}>
                <Typography sx={typoValue}>Company Employee Id</Typography>
                {editableED ? (
                  <TextField
                    fullWidth
                    className="formInputs"
                    placeholder="Enter company Employee Id"
                    value={data.companyemployeeid}
                    onChange={handleChange}
                    name="companyemployeeid"
                  />
                ) : (
                  <Typography sx={typoHeading}>
                    {data && data.companyemployeeid ? data.companyemployeeid : "--"}
                  </Typography>
                )}
              </Grid>

            </Grid>
          </Stack>
          {editableED && (
            <Box sx={saveBtnBox}>
              <Button
                className="cancelBtn"
                variant="outlined"
                onClick={() => setEditableED(false)}
              >
                Cancel
              </Button>
              <Button
                className="saveBtn"
                variant="contained"
                onClick={handleEdit}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>

        {/*----------------Employee Document------------------*/}
        <Box sx={boxStyle}>
          <Typography sx={typoHeading}>Employment Document</Typography>
          <br />
          <Divider></Divider>
          <br />
          <Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                sx={(typoValue, { display: "flex", alignItems: "center" })}
              >
                <span>Bonafide</span>
                <span>
                  <Typography sx={typoValue}>
                    <IconButton>
                      <AttachFileIcon />
                    </IconButton>
                  </Typography>
                </span>
                {editableBnf ? (
                  <input
                    type="file"
                    onChange={(e) => handleUpload(e, "bonafide")}
                    name="bonafide"
                    data={data.bonafide}
                  />
                ) : (
                  <span>
                    {data && data.bonafide ? (
                      <a
                        href={data.bonafide}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {truncateText(getFileNameFromUrl(data.bonafide), 20)}
                      </a>
                    ) : (
                      "--"
                    )}
                  </span>
                )}
              </Typography>
              {editableBnf ? (
                <Box sx={saveBtnBox}>
                  <Button
                    className="cancelBtn"
                    variant="outlined"
                    onClick={() => setEditableBnf(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="saveBtn"
                    variant="contained"
                    onClick={handleEdit}
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  endIcon={<EditIcon />}
                  onClick={() => handleClickUploadEdit("Bonafide")}
                >
                  Edit
                </Button>
              )}
            </Box>
            <br />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                sx={(typoValue, { display: "flex", alignItems: "center" })}
              >
                <span>Appraisal</span>
                <span>
                  <Typography sx={typoValue}>
                    <IconButton>
                      <AttachFileIcon />
                    </IconButton>
                  </Typography>
                </span>
                {editableAprsl ? (
                  <input
                    type="file"
                    onChange={(e) => handleUpload(e, "appraisal")}
                    name="appraisal"
                    data={data.appraisal}
                  />
                ) : (
                  <span>
                    {data && data.appraisal ? (
                      <a
                        href={data.appraisal}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {truncateText(getFileNameFromUrl(data.appraisal), 20)}
                      </a>
                    ) : (
                      "--"
                    )}
                  </span>
                )}
              </Typography>
              {editableAprsl ? (
                <Box sx={saveBtnBox}>
                  <Button
                    className="cancelBtn"
                    variant="outlined"
                    onClick={() => setEditableAprsl(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="saveBtn"
                    variant="contained"
                    onClick={handleEdit}
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  endIcon={<EditIcon />}
                  onClick={() => handleClickUploadEdit("Appraisal")}
                >
                  Edit
                </Button>
              )}
            </Box>
            <br />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                sx={(typoValue, { display: "flex", alignItems: "center" })}
              >
                <span>Reccomendation Certificate</span>
                <span>
                  <Typography sx={typoValue}>
                    <IconButton>
                      <AttachFileIcon />
                    </IconButton>
                  </Typography>
                </span>
                {editableRC ? (
                  <input
                    type="file"
                    onChange={(e) => handleUpload(e, "ratingcard")}
                    name="ratingcard"
                    data={data.ratingcard}
                  />
                ) : (
                  <span>
                    {data && data.ratingcard ? (
                      <a
                        href={data.ratingcard}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Reccomendation Certificate
                      </a>
                    ) : (
                      "--"
                    )}
                  </span>
                )}
              </Typography>
              {editableRC ? (
                <Box sx={saveBtnBox}>
                  <Button
                    className="cancelBtn"
                    variant="outlined"
                    onClick={() => setEditableRC(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="saveBtn"
                    variant="contained"
                    onClick={handleEdit}
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  endIcon={<EditIcon />}
                  onClick={() =>
                    handleClickUploadEdit("Reccomendation Certificate")
                  }
                >
                  Edit
                </Button>
              )}
            </Box>
            <br />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                sx={(typoValue, { display: "flex", alignItems: "center" })}
              >
                <span>Bond</span>
                <span>
                  <Typography sx={typoValue}>
                    <IconButton>
                      <AttachFileIcon />
                    </IconButton>
                  </Typography>
                </span>
                {editableBond ? (
                  <input
                    type="file"
                    onChange={(e) => handleUpload(e, "bond")}
                    name="bond"
                    data={data.bond}
                  />
                ) : (
                  <span>
                    {data && data.bond ? (
                      <a
                        href={data.bond}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Bond
                      </a>
                    ) : (
                      "--"
                    )}
                  </span>
                )}
              </Typography>
              {editableBond ? (
                <Box sx={saveBtnBox}>
                  <Button
                    className="cancelBtn"
                    variant="outlined"
                    onClick={() => setEditableBond(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="saveBtn"
                    variant="contained"
                    onClick={handleEdit}
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  endIcon={<EditIcon />}
                  onClick={() => handleClickUploadEdit("Bond")}
                >
                  Edit
                </Button>
              )}
            </Box>
            <br />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                sx={(typoValue, { display: "flex", alignItems: "center" })}
              >
                <span>NDA</span>
                <span>
                  <Typography sx={typoValue}>
                    <IconButton>
                      <AttachFileIcon />
                    </IconButton>
                  </Typography>
                </span>
                {editableNDA ? (
                  <input
                    type="file"
                    onChange={(e) => handleUpload(e, "nda")}
                    name="nda"
                    data={data.nda}
                  />
                ) : (
                  <span>
                    {data && data.nda ? (
                      <a
                        href={data.nda}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        NDA
                      </a>
                    ) : (
                      "--"
                    )}
                  </span>
                )}
              </Typography>
              {editableNDA ? (
                <Box sx={saveBtnBox}>
                  <Button
                    className="cancelBtn"
                    variant="outlined"
                    onClick={() => setEditableNDA(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="saveBtn"
                    variant="contained"
                    onClick={handleEdit}
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  endIcon={<EditIcon />}
                  onClick={() => handleClickUploadEdit("NDA")}
                >
                  Edit
                </Button>
              )}
            </Box>
          </Box>
        </Box>

        {/*----------------Personal Details------------------*/}
        <Box sx={boxStyle}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography sx={typoHeading}>Personal Details</Typography>
            {!editablePD && (
              <Button
                variant="outlined"
                endIcon={<EditIcon />}
                onClick={() => handleClickEdit("Personal Details")}
              >
                Edit
              </Button>
            )}
          </Box>
          <br />
          <Divider></Divider>
          <br />
          <Stack direction="row" justifyContent={"space-between"}>
            <Grid container spacing={2}>
              <Grid item xs={2.4} container direction="column" spacing={2}>
                <Grid item>
                  <Typography sx={typoValue}>Name</Typography>
                  {editablePD ? (
                    <TextField
                      fullWidth
                      className="formInputs"
                      placeholder="Enter Name"
                      value={data.name}
                      onChange={handleChange}
                      name="name"
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.name ? data.name : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>Pincode</Typography>
                  {editablePD ? (
                    <TextField
                      fullWidth
                      type="number"
                      className="formInputs"
                      value={formData.pincode}
                      onChange={handleInputChange}
                      error={errors.pincode}
                      helperText={errors.pincode && errorMessages.pincode}
                      name="pincode"
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {formData && formData.pincode ? formData.pincode : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>LinkedIn</Typography>
                  {editablePD ? (
                    <TextField
                      fullWidth
                      className="formInputs"
                      placeholder="Enter URL"
                      value={data.linkedin}
                      onChange={handleChange}
                      name="linkedin"
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.linkedin ? data.linkedin : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>Country of Origin</Typography>
                  {editablePD ? (
                    <Select
                      fullWidth
                      className="formInputs"
                      inputProps={{
                        className: "selectInput",
                      }}
                      placeholder="Enter Name"
                      value={data.countryoforigin}
                      onChange={handleChange}
                      name="countryoforigin"
                    >
                      {allCountries.length > 0 &&
                        allCountries.map((ele) => (
                          <MenuItem value={ele.name} key={ele.name}>
                            {ele.name}
                          </MenuItem>
                        ))}
                    </Select>
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.countryoforigin
                        ? data.countryoforigin
                        : "--"}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={2.4} container direction="column" spacing={2}>
                <Grid item>
                  <Typography sx={typoValue}>Address</Typography>
                  {editablePD ? (
                    <TextField
                      fullWidth
                      className="formInputs"
                      placeholder="Enter Full Address"
                      value={data.address}
                      onChange={handleChange}
                      name="address"
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.address ? data.address : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>Contact Number</Typography>
                  {editablePD ? (
                    <TextField
                      fullWidth
                      type="number"
                      className="formInputs"
                      placeholder="Enter Number"
                      value={formData.contactno}
                      onChange={handleInputChange}
                      error={errors.contactno}
                      helperText={errors.contactno && errorMessages.contactno}
                      name="contactno"
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {formData && formData.contactno
                        ? formData.contactno
                        : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>Twitter</Typography>
                  {editablePD ? (
                    <TextField
                      fullWidth
                      className="formInputs"
                      placeholder="Enter URL"
                      value={data.twitter}
                      onChange={handleChange}
                      name="twitter"
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.twitter ? data.twitter : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>Place of Birth</Typography>
                  {editablePD ? (
                    //   <Select
                    //     fullWidth
                    //     className="formInputs"
                    //     inputProps={{
                    //       className: "selectInput",
                    //     }}
                    //     value={data.birthplace}
                    //     onChange={handleChange}
                    //     name="birthplace"
                    //   >
                    //     {states1?.map((ele, ind) => (
                    //       <MenuItem key={ind} value={ele.name}>
                    //         {ele.name}
                    //       </MenuItem>
                    //     ))}
                    //   </Select>
                    // ) : (
                    <TextField
                      fullWidth
                      type="text"
                      className="formInputs"
                      placeholder="Enter Birth Place"
                      value={data.birthplace}
                      onChange={handleChange}
                      name="birthplace"
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.birthplace ? data.birthplace : "--"}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={2.4} container direction="column" spacing={2}>
                <Grid item>
                  <Typography sx={typoValue}>Country</Typography>
                  {editablePD ? (
                    <Select
                      fullWidth
                      className="formInputs"
                      inputProps={{
                        className: "selectInput",
                      }}
                      value={data.country}
                      onChange={handleCountryChange} // Use the new handler here
                      name="country"
                    >
                      {allCountries?.map((ele, ind) => (
                        <MenuItem key={ind} value={ele.name}>
                          {ele.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.country ? data.country : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>Date of Birth</Typography>
                  {editablePD ? (
                    <TextField
                      fullWidth
                      type="date"
                      className="formInputs"
                      placeholder="Enter Number"
                      value={data.dob}
                      onChange={handleChange}
                      name="dob"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputProps: {
                          max: new Date().toISOString().split("T")[0], // Restrict to future dates only
                        },
                      }}
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.dob ? formatDate(data.dob) : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>Marital Status</Typography>
                  {editablePD ? (
                    <Select
                      fullWidth
                      className="formInputs"
                      inputProps={{
                        className: "selectInput",
                      }}
                      placeholder="Enter Name"
                      value={data.maritalstatus}
                      onChange={handleChange}
                      name="maritalstatus"
                    >
                      <MenuItem value="Single">Single</MenuItem>
                      <MenuItem value="Married">Married</MenuItem>
                      <MenuItem value="Divorced">Divorced</MenuItem>
                      <MenuItem value="Widow">Widow</MenuItem>
                    </Select>
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.maritalstatus ? data.maritalstatus : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>Religion</Typography>
                  {editablePD ? (
                    <Select
                      fullWidth
                      className="formInputs"
                      inputProps={{
                        className: "selectInput",
                      }}
                      placeholder="Enter Name"
                      value={data.religion}
                      onChange={handleChange}
                      name="religion"
                    >
                      <MenuItem value="Christianity">Christianity</MenuItem>
                      <MenuItem value="Islam">Islam</MenuItem>
                      <MenuItem value="Hinduism">Hinduism</MenuItem>
                      <MenuItem value="Buddhism">Buddhism</MenuItem>
                    </Select>
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.religion ? data.religion : "--"}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={2.4} container direction="column" spacing={2}>
                <Grid item>
                  <Typography sx={typoValue}>State</Typography>
                  {editablePD ? (
                    <Select
                      fullWidth
                      className="formInputs"
                      inputProps={{
                        className: "selectInput",
                      }}
                      value={data.state}
                      onChange={handleStateChange} // Use the new handler here
                      name="state"
                    // disabled={!data.country}
                    >
                      {states?.map((ele, ind) => (
                        <MenuItem key={ind} value={ele.name}>
                          {ele.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.state ? data.state : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>Gender</Typography>
                  {editablePD ? (
                    <Select
                      fullWidth
                      className="formInputs"
                      inputProps={{
                        className: "selectInput",
                      }}
                      placeholder="Enter Name"
                      value={data.gender}
                      onChange={handleChange}
                      name="gender"
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.gender ? data.gender : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>Nationality</Typography>
                  {editablePD ? (
                    <Select
                      fullWidth
                      className="formInputs"
                      inputProps={{
                        className: "selectInput",
                      }}
                      placeholder="Enter Name"
                      value={data.nationality}
                      onChange={handleChange}
                      name="nationality"
                    >
                      {[
                        "Indian",
                        "Chinese",
                        "Pakistani",
                        "Russian",
                        "American",
                        "British",
                        "Japanese",
                        "Indonesian",
                        "Brazilian",
                        "Nigerian",
                        "Bangladeshi",
                        "German",
                        "African",
                        "Srilankan",
                        "Mexican",
                        "French",
                        "Italian",
                        "Spanish",
                        "Canadian",
                        "Australian",
                        "South Korean",
                        "Turkish",
                        "Brazilian",
                        "Arabic",
                        "Portuguese",
                        "Dutch",
                        "Swedish",
                        "Greek",
                        "Danish",
                        "Farsi (Persian)",
                        "Norwegian",
                        "Polish",
                        "Finnish",
                        "Hungarian",
                      ].map((country) => (
                        <MenuItem value={country} key={country}>
                          {country}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.nationality ? data.nationality : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>Physically Challenged</Typography>
                  {editablePD ? (
                    <Select
                      fullWidth
                      className="formInputs"
                      inputProps={{
                        className: "selectInput",
                      }}
                      placeholder="Enter Name"
                      value={data.physically}
                      onChange={handleChange}
                      name="physically"
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.physically ? data.physically : "--"}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={2.4} container direction="column" spacing={2}>
                <Grid item>
                  <Typography sx={typoValue}>City</Typography>
                  {editablePD ? (
                    <Select
                      fullWidth
                      className="formInputs"
                      inputProps={{
                        className: "selectInput",
                      }}
                      placeholder="Enter Name"
                      value={data.city}
                      onChange={handleChange}
                      name="city"
                    >
                      {cities?.map((ele, ind) => (
                        <MenuItem key={ind} value={ele.name}>
                          {ele.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.city ? data.city : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>Personal Email</Typography>
                  {editablePD ? (
                    <TextField
                      fullWidth
                      className="formInputs"
                      placeholder="Personal Email ID"
                      value={formData.personalemail}
                      onChange={handleInputChange}
                      error={errors.personalemail}
                      helperText={
                        errors.personalemail && errorMessages.personalemail
                      }
                      name="personalemail"
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {formData && formData.personalemail
                        ? formData.personalemail
                        : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>Residential Status</Typography>
                  {editablePD ? (
                    <Select
                      fullWidth
                      className="formInputs"
                      inputProps={{
                        className: "selectInput",
                      }}
                      placeholder="Enter Name"
                      value={data.residentstatus}
                      onChange={handleChange}
                      name="residentstatus"
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.residentstatus ? data.residentstatus : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>International Employee</Typography>
                  {editablePD ? (
                    <Select
                      fullWidth
                      className="formInputs"
                      inputProps={{
                        className: "selectInput",
                      }}
                      placeholder="Enter Name"
                      value={data.isinternational}
                      onChange={handleChange}
                      name="isinternational"
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.isinternational
                        ? data.isinternational
                        : "--"}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Stack>

          {editablePD && (
            <Box sx={saveBtnBox}>
              <Button
                className="cancelBtn"
                variant="outlined"
                onClick={() => setEditablePD(false)}
              >
                Cancel
              </Button>
              <Button
                className="saveBtn"
                variant="contained"
                onClick={handleEdit}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>

        {/*----------------Personal Document------------------*/}
        <Box sx={boxStyle}>
          <Typography sx={typoHeading}>Personal Document</Typography>
          <br />
          <Divider></Divider>
          <br />
          <Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                sx={(typoValue, { display: "flex", alignItems: "center" })}
              >
                <span>Pan</span>
                <span>
                  <Typography sx={typoValue}>
                    <IconButton>
                      <AttachFileIcon />
                    </IconButton>
                  </Typography>
                </span>
                {editablePAN ? (
                  <input
                    type="file"
                    onChange={(e) => handleUpload(e, "pan")}
                    name="pan"
                    data={data.pan}
                  />
                ) : (
                  <span>
                    {data && data.pan ? (
                      <a
                        href={data.pan}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Pan
                      </a>
                    ) : (
                      "--"
                    )}
                  </span>
                )}
              </Typography>
              {editablePAN ? (
                <Box sx={saveBtnBox}>
                  <Button
                    className="cancelBtn"
                    variant="outlined"
                    onClick={() => setEditablePAN(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="saveBtn"
                    variant="contained"
                    onClick={handleEdit}
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  endIcon={<EditIcon />}
                  onClick={() => handleClickUploadEdit("PAN")}
                >
                  Edit
                </Button>
              )}
            </Box>
            {editablePAN ? (
              <TextField
                className="formInputs"
                placeholder="Enter Pan Number"
                value={formData.panno}
                onChange={handleInputChange}
                error={errors.panno}
                helperText={errors.panno && errorMessages.panno}
                name="panno"
              />
            ) : (
              <Typography sx={typoHeading}>
                {formData && formData.panno ? formData.panno : "--"}
              </Typography>
            )}
            <br />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                sx={(typoValue, { display: "flex", alignItems: "center" })}
              >
                <span>Aadhar</span>
                <span>
                  <Typography sx={typoValue}>
                    <IconButton>
                      <AttachFileIcon />
                    </IconButton>
                  </Typography>
                </span>
                {editableADH ? (
                  <input
                    type="file"
                    onChange={(e) => handleUpload(e, "aadhar")}
                    name="aadhar"
                    data={data.aadhar}
                  />
                ) : (
                  <span>
                    {data && data.aadhar ? (
                      <a
                        href={data.aadhar}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Aadhar
                      </a>
                    ) : (
                      "--"
                    )}
                  </span>
                )}
              </Typography>
              {editableADH ? (
                <Box sx={saveBtnBox}>
                  <Button
                    className="cancelBtn"
                    variant="outlined"
                    onClick={() => setEditableADH(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="saveBtn"
                    variant="contained"
                    onClick={handleEdit}
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  endIcon={<EditIcon />}
                  onClick={() => handleClickUploadEdit("Aadhaar")}
                >
                  Edit
                </Button>
              )}
            </Box>
            {editableADH ? (
              <TextField
                className="formInputs"
                placeholder="Enter Aadhar Number"
                value={formData.aadharno}
                onChange={handleInputChange}
                error={errors.aadharno}
                helperText={errors.aadharno && errorMessages.aadharno}
                name="aadharno"
              />
            ) : (
              <Typography sx={typoHeading}>
                {formData && formData.aadharno ? formData.aadharno : "--"}
              </Typography>
            )}
            <br />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                sx={(typoValue, { display: "flex", alignItems: "center" })}
              >
                <span>Passport</span>
                <span>
                  <Typography sx={typoValue}>
                    <IconButton>
                      <AttachFileIcon />
                    </IconButton>
                  </Typography>
                </span>
                {editablePP ? (
                  <input
                    type="file"
                    onChange={(e) => handleUpload(e, "passport")}
                    name="passport"
                    data={data.passport}
                  />
                ) : (
                  <span>
                    {data && data.passport ? (
                      <a
                        href={data.passport}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Passport
                      </a>
                    ) : (
                      "--"
                    )}
                  </span>
                )}
              </Typography>
              {editablePP ? (
                <Box sx={saveBtnBox}>
                  <Button
                    className="cancelBtn"
                    variant="outlined"
                    onClick={() => setEditablePP(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="saveBtn"
                    variant="contained"
                    onClick={handleEdit}
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  endIcon={<EditIcon />}
                  onClick={() => handleClickUploadEdit("Passport")}
                >
                  Edit
                </Button>
              )}
            </Box>
            {editablePP ? (
              <TextField
                className="formInputs"
                placeholder="Enter Passport Number"
                value={data.passportno}
                onChange={handleChange}
                name="passportno"
              />
            ) : (
              <Typography sx={typoHeading}>
                {data && data.passportno ? data.passportno : "--"}
              </Typography>
            )}
            <br />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                sx={(typoValue, { display: "flex", alignItems: "center" })}
              >
                <span>Experience Certificate</span>
                <span>
                  <Typography sx={typoValue}>
                    <IconButton>
                      <AttachFileIcon />
                    </IconButton>
                  </Typography>
                </span>
                {editableEXPC ? (
                  <input
                    type="file"
                    onChange={(e) => handleUpload(e, "expcertificate")}
                    name="expcertificate"
                    data={data.expcertificate}
                  />
                ) : (
                  <span>
                    {data && data.expcertificate ? (
                      <a
                        href={data.expcertificate}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Experience Certificate
                      </a>
                    ) : (
                      "--"
                    )}
                  </span>
                )}
              </Typography>
              {editableEXPC ? (
                <Box sx={saveBtnBox}>
                  <Button
                    className="cancelBtn"
                    variant="outlined"
                    onClick={() => setEditableEXPC(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="saveBtn"
                    variant="contained"
                    onClick={handleEdit}
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  endIcon={<EditIcon />}
                  onClick={() =>
                    handleClickUploadEdit("Experience Certificate")
                  }
                >
                  Edit
                </Button>
              )}
            </Box>
            <Typography sx={typoHeading}>--</Typography>
            <br />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                sx={(typoValue, { display: "flex", alignItems: "center" })}
              >
                <span>Payslip</span>
                <span>
                  <Typography sx={typoValue}>
                    <IconButton>
                      <AttachFileIcon />
                    </IconButton>
                  </Typography>
                </span>
                {editablePS ? (
                  <input
                    type="file"
                    onChange={(e) => handleUpload(e, "payslip")}
                    name="payslip"
                    data={data.payslip}
                  />
                ) : (
                  <span>
                    {data && data.payslip ? (
                      <a
                        href={data.payslip}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Payslip
                      </a>
                    ) : (
                      "--"
                    )}
                  </span>
                )}
              </Typography>
              {editablePS ? (
                <Box sx={saveBtnBox}>
                  <Button
                    className="cancelBtn"
                    variant="outlined"
                    onClick={() => setEditablePS(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="saveBtn"
                    variant="contained"
                    onClick={handleEdit}
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  endIcon={<EditIcon />}
                  onClick={() => handleClickUploadEdit("Payslip")}
                >
                  Edit
                </Button>
              )}
            </Box>
            <Typography sx={typoHeading}>--</Typography>
            <br />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                sx={(typoValue, { display: "flex", alignItems: "center" })}
              >
                <span>Bank Statement</span>
                <span>
                  <Typography sx={typoValue}>
                    <IconButton>
                      <AttachFileIcon />
                    </IconButton>
                  </Typography>
                </span>
                {editableBankS ? (
                  <input
                    type="file"
                    onChange={(e) => handleUpload(e, "bankstatement")}
                    name="bankstatement"
                    data={data.bankstatement}
                  />
                ) : (
                  <span>
                    {data && data.bankstatement ? (
                      <a
                        href={data.bankstatement}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Bank Statement
                      </a>
                    ) : (
                      "--"
                    )}
                  </span>
                )}
              </Typography>
              {editableBankS ? (
                <Box sx={saveBtnBox}>
                  <Button
                    className="cancelBtn"
                    variant="outlined"
                    onClick={() => setEditableBankS(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="saveBtn"
                    variant="contained"
                    onClick={handleEdit}
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  endIcon={<EditIcon />}
                  onClick={() => handleClickUploadEdit("Bank Statement")}
                >
                  Edit
                </Button>
              )}
            </Box>
            <Typography sx={typoHeading}>--</Typography>
          </Box>
        </Box>

        <Box sx={boxStyle}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography sx={typoHeading}>Education Details</Typography>
            {!editableEdu && (
              <Button
                variant="outlined"
                endIcon={<EditIcon />}
                onClick={() => handleClickEdit("Education Details")}
              >
                Edit
              </Button>
            )}
          </Box>
          <br />
          <Divider />
          <br />

          {/* Map through educationarray to display or edit each education record */}
          {data.educationarray &&
            data.educationarray.map((edu, index) => (
              <Stack
                key={index}
                direction="row"
                justifyContent={"space-between"}
                spacing={2}
                mb={2}
              >
                <Box flex={1}>
                  <Typography sx={typoValue}>Education</Typography>
                  {editableEdu ? (
                    <TextField
                      fullWidth
                      className="formInputs"
                      placeholder="Enter Education"
                      value={edu.educationname}
                      onChange={(e) =>
                        handleEducationChange(e, index, "educationname")
                      }
                      disabled={
                        edu.educationname === "10th" ||
                        edu.educationname === "12th"
                      }
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {edu.educationname}
                    </Typography>
                  )}
                </Box>
                <Box flex={1}>
                  <Typography sx={typoValue}>Type</Typography>
                  {editableEdu ? (
                    <TextField
                      fullWidth
                      className="formInputs"
                      placeholder="Enter Education Type"
                      value={
                        edu.educationtype
                          ? edu.educationtype.charAt(0).toUpperCase() +
                          edu.educationtype.slice(1)
                          : ""
                      }
                      onChange={(e) =>
                        handleEducationChange(e, index, "educationtype")
                      }
                      disabled
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {edu.educationtype
                        ? edu.educationtype.charAt(0).toUpperCase() +
                        edu.educationtype.slice(1)
                        : "-"}
                    </Typography>
                  )}
                </Box>
                <Box flex={1}>
                  <Typography sx={typoValue}>Marksheet</Typography>
                  {edu.marksheet ? (
                    <Button
                      onClick={() => window.open(edu.marksheet, "_blank")}
                      startIcon={<AttachFileIcon />}
                    >
                      Download
                    </Button>
                  ) : (
                    <Typography sx={typoHeading}>-</Typography>
                  )}
                </Box>
                <Box flex={1}>
                  <Typography sx={typoValue}>Percentage</Typography>
                  {editableEdu ? (
                    <TextField
                      fullWidth
                      className="formInputs"
                      placeholder="Enter Percentage"
                      value={edu.percentage}
                      onChange={(e) =>
                        handleEducationChange(e, index, "percentage")
                      }
                    />
                  ) : (
                    <Typography sx={typoHeading}>{edu.percentage}</Typography>
                  )}
                </Box>
                <Box flex={1}>
                  <Typography sx={typoValue}>Passout Year</Typography>
                  {editableEdu ? (
                    <TextField
                      fullWidth
                      type="number"
                      className="formInputs"
                      value={extractYear(edu.passoutyear)}
                      onChange={(e) =>
                        handleEducationChange(e, index, "passoutyear")
                      }
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {extractYear(edu.passoutyear)}
                    </Typography>
                  )}
                </Box>
              </Stack>
            ))}

          {/* Display Save and Cancel buttons if editable */}
          {editableEdu && (
            <Box sx={saveBtnBox} mt={2}>
              <Button
                className="cancelBtn"
                variant="outlined"
                onClick={cancelEducationEdit}
              >
                Cancel
              </Button>
              <Button
                className="saveBtn"
                variant="contained"
                onClick={saveEducationDetails}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>

        {/*----------------Permanent Address------------------*/}
        <Box sx={boxStyle}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography sx={typoHeading}>Permanent Address</Typography>
            {!editablePA && (
              <Button
                variant="outlined"
                endIcon={<EditIcon />}
                onClick={() => handleClickEdit("Permanent Address")}
              >
                Edit
              </Button>
            )}
          </Box>
          <br />
          <Divider />
          <br />
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            flexWrap="wrap"
          >
            <Box sx={{ flex: "1 1 18%", minWidth: "18%" }}>
              <Typography sx={typoValue}>Address</Typography>
              {editablePA ? (
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="Enter Full Address"
                  value={data.paaddress}
                  onChange={handleChange}
                  name="paaddress"
                />
              ) : (
                <Typography sx={typoHeading}>
                  {data && data.paaddress ? data.paaddress : "--"}
                </Typography>
              )}
            </Box>
            <Box sx={{ flex: "1 1 18%", minWidth: "18%" }}>
              <Typography sx={typoValue}>Country</Typography>
              {editablePA ? (
                <Select
                  fullWidth
                  className="formInputs"
                  inputProps={{ className: "selectInput" }}
                  value={data.pacountry}
                  onChange={handlePaCountryChange}
                  name="pacountry"
                >
                  {allCountries.map((ele) => (
                    <MenuItem value={ele.name} key={Math.random() + Date.now()}>
                      {ele.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Typography sx={typoHeading}>
                  {data && data.pacountry ? data.pacountry : "--"}
                </Typography>
              )}
            </Box>
            <Box sx={{ flex: "1 1 18%", minWidth: "18%" }}>
              <Typography sx={typoValue}>State</Typography>
              {editablePA ? (
                <Select
                  fullWidth
                  className="formInputs"
                  inputProps={{ className: "selectInput" }}
                  value={data.pastate}
                  onChange={handlePaStateChange}
                  name="pastate"
                >
                  {paStates.map((ele, ind) => (
                    <MenuItem key={ind} value={ele.name}>
                      {ele.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Typography sx={typoHeading}>
                  {data && data.pastate ? data.pastate : "--"}
                </Typography>
              )}
            </Box>
            <Box sx={{ flex: "1 1 18%", minWidth: "18%" }}>
              <Typography sx={typoValue}>City</Typography>
              {editablePA ? (
                <Select
                  fullWidth
                  className="formInputs"
                  inputProps={{ className: "selectInput" }}
                  value={data.pacity}
                  onChange={handleChange}
                  name="pacity"
                >
                  {paCities.map((ele, ind) => (
                    <MenuItem key={ind} value={ele.name}>
                      {ele.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Typography sx={typoHeading}>
                  {data && data.pacity ? data.pacity : "--"}
                </Typography>
              )}
            </Box>
            <Box sx={{ flex: "1 1 18%", minWidth: "18%" }}>
              <Typography sx={typoValue}>Pincode</Typography>
              {editablePA ? (
                <TextField
                  fullWidth
                  type="number"
                  className="formInputs"
                  value={formData.papincode}
                  onChange={handleInputChange}
                  error={errors.papincode}
                  helperText={errors.papincode && errorMessages.papincode}
                  name="papincode"
                />
              ) : (
                <Typography sx={typoHeading}>
                  {formData && formData.papincode ? formData.papincode : "--"}
                </Typography>
              )}
            </Box>
          </Stack>
          {editablePA && (
            <Box sx={saveBtnBox}>
              <Button
                className="cancelBtn"
                variant="outlined"
                onClick={() => setEditablePA(false)}
              >
                Cancel
              </Button>
              <Button
                className="saveBtn"
                variant="contained"
                onClick={handleEdit}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>

        {/*----------------Emergency Contact------------------*/}
        <Box sx={boxStyle}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography sx={typoHeading}>Emergency Contact</Typography>
            {!editableEC && (
              <Button
                variant="outlined"
                endIcon={<EditIcon />}
                onClick={() => handleClickEdit("Emergency Contact")}
              >
                Edit
              </Button>
            )}
          </Box>
          <br />
          <Divider></Divider>
          <br />
          <Stack direction="row" justifyContent={"space-between"}>
            <Grid container spacing={2}>
              <Grid item xs={2.4} container direction="column" spacing={2}>
                <Grid item>
                  <Typography sx={typoValue}>Name</Typography>
                  {editableEC ? (
                    <TextField
                      fullWidth
                      className="formInputs"
                      placeholder="Enter Name"
                      value={data.ecname}
                      onChange={handleChange}
                      name="ecname"
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.ecname ? data.ecname : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>State</Typography>
                  {editableEC ? (
                    <Select
                      fullWidth
                      className="formInputs"
                      inputProps={{
                        className: "selectInput",
                      }}
                      value={data.ecstate}
                      onChange={handleEcStateChange}
                      name="ecstate"
                    >
                      {ecStates?.map((ele, ind) => (
                        <MenuItem key={ind} value={ele.name}>
                          {ele.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.ecstate ? data.ecstate : "--"}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={2.4} container direction="column" spacing={2}>
                <Grid item>
                  <Typography sx={typoValue}>Relationship</Typography>
                  {editableEC ? (
                    <TextField
                      fullWidth
                      className="formInputs"
                      placeholder="Enter Relation"
                      value={data.ecrelation}
                      onChange={handleChange}
                      name="ecrelation"
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.ecrelation ? data.ecrelation : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>City</Typography>
                  {editableEC ? (
                    <Select
                      fullWidth
                      className="formInputs"
                      inputProps={{
                        className: "selectInput",
                      }}
                      value={data.eccity}
                      onChange={handleChange}
                      name="eccity"
                    >
                      {ecCities?.map((ele, ind) => (
                        <MenuItem key={ind} value={ele.name}>
                          {ele.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.eccity ? data.eccity : "--"}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={2.4} container direction="column" spacing={2}>
                <Grid item>
                  <Typography sx={typoValue}>Contact Number</Typography>
                  {editableEC ? (
                    <TextField
                      fullWidth
                      type="number"
                      className="formInputs"
                      placeholder="Enter Number"
                      value={formData.eccontactno}
                      onChange={handleInputChange}
                      error={errors.eccontactno}
                      helperText={
                        errors.eccontactno && errorMessages.eccontactno
                      }
                      name="eccontactno"
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {formData && formData.eccontactno
                        ? formData.eccontactno
                        : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>Pin Code</Typography>
                  {editableEC ? (
                    <TextField
                      fullWidth
                      type="number"
                      className="formInputs"
                      value={formData.ecpincode}
                      onChange={handleInputChange}
                      error={errors.ecpincode}
                      helperText={errors.ecpincode && errorMessages.ecpincode}
                      name="ecpincode"
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {formData && formData.ecpincode
                        ? formData.ecpincode
                        : "--"}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={2.4} container direction="column" spacing={2}>
                <Grid item>
                  <Typography sx={typoValue}>Address</Typography>
                  {editableEC ? (
                    <TextField
                      fullWidth
                      className="formInputs"
                      placeholder="Enter Full Address"
                      value={data.ecaddress}
                      onChange={handleChange}
                      name="ecaddress"
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.ecaddress ? data.ecaddress : "--"}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={typoValue}>E-mail ID</Typography>
                  {editableEC ? (
                    <TextField
                      fullWidth
                      className="formInputs"
                      placeholder="Enter Email ID"
                      value={formData.ecemail}
                      onChange={handleInputChange}
                      error={errors.ecemail}
                      helperText={errors.ecemail && errorMessages.ecemail}
                      name="ecemail"
                    />
                  ) : (
                    <Typography sx={typoHeading}>
                      {formData && formData.ecemail ? formData.ecemail : "--"}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={2.4} container direction="column" spacing={2}>
                <Grid item>
                  <Typography sx={typoValue}>Country</Typography>
                  {editableEC ? (
                    <Select
                      fullWidth
                      className="formInputs"
                      inputProps={{
                        className: "selectInput",
                      }}
                      value={data.eccountry}
                      onChange={handleEcCountryChange}
                      name="eccountry"
                    >
                      {allCountries.length > 0 &&
                        allCountries.map((ele) => (
                          <MenuItem value={ele.name} key={ele.name}>
                            {ele.name}
                          </MenuItem>
                        ))}
                    </Select>
                  ) : (
                    <Typography sx={typoHeading}>
                      {data && data.eccountry ? data.eccountry : "--"}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Stack>

          {editableEC && (
            <Box sx={saveBtnBox}>
              <Button
                className="cancelBtn"
                variant="outlined"
                onClick={() => setEditableEC(false)}
              >
                Cancel
              </Button>
              <Button
                className="saveBtn"
                variant="contained"
                onClick={handleEdit}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>

        {/*----------------Document Verification------------------*/}
        <Box sx={boxStyle}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography sx={typoHeading}>Document Verification</Typography>
            {!editableDV && (
              <Button
                variant="outlined"
                endIcon={<EditIcon />}
                onClick={() => handleClickEdit("Document Verification")}
              >
                Edit
              </Button>
            )}
          </Box>
          <br />
          <Divider></Divider>
          <br />
          <Stack direction="row" justifyContent={"space-between"}>
            <Box>
              <Typography sx={typoValue}>Verification Status</Typography>
              {editableDV ? (
                <Select
                  fullWidth
                  className="formInputs"
                  inputProps={{
                    className: "selectInput",
                  }}
                  value={data.verifystatus}
                  onChange={handleChange}
                  name="verifystatus"
                >
                  <MenuItem value="In-Progress">In-Progress</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                </Select>
              ) : (
                <Typography sx={typoHeading}>
                  {data && data.verifystatus ? data.verifystatus : "--"}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography sx={typoValue}>
                {editableDV && data.verifystatus == "Completed"
                  ? "Verification Completed Date *"
                  : "Verification Completed Date"}
              </Typography>
              {editableDV ? (
                <TextField
                  fullWidth
                  type="date"
                  className="formInputs"
                  value={data.verifydate}
                  onChange={handleChange}
                  name="verifydate"
                  disabled={data.verifystatus != "Completed"}
                />
              ) : (
                <Typography sx={typoHeading}>
                  {data && data.verifydate ? formatDate(data.verifydate) : "--"}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography sx={typoValue}>Agency Name</Typography>
              {editableDV ? (
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="Enter Agency Name"
                  value={data.agency}
                  onChange={handleChange}
                  name="agency"
                />
              ) : (
                <Typography sx={typoHeading}>
                  {data && data.agency ? data.agency : "--"}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography sx={typoValue}>Remarks</Typography>
              {editableDV ? (
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="Enter Remarks"
                  value={data.remarks}
                  onChange={handleChange}
                  name="remarks"
                />
              ) : (
                <Typography sx={typoHeading}>
                  {data && data.remarks ? data.remarks : "--"}
                </Typography>
              )}
            </Box>
            <Box visibility={"hidden"}>
              <Typography sx={typoValue}>Dummy</Typography>
              <Typography sx={typoHeading}>{"--"}</Typography>
            </Box>
          </Stack>
          {editableDV && (
            <Box sx={saveBtnBox}>
              <Button
                className="cancelBtn"
                variant="outlined"
                onClick={() => setEditableDV(false)}
              >
                Cancel
              </Button>
              <Button
                className="saveBtn"
                variant="contained"
                onClick={handleEdit}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>

      </Box>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default ViewEditEmployee;
