import { Button } from '@mui/material'
import React from 'react'

function CommonButton({ children, type, variant, onClick }) {
    return (
        <Button type={type} variant={variant} fullWidth className={`mobile-commonbutton ${variant === "contained" ? 'contained' : null}`} sx={{ marginTop: "1rem" }} onClick={type == "button" ? onClick : null}>
            {children}
        </Button>
    )
}

export default CommonButton