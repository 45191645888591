import { Box, Button, Divider, Paper, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useCallback, useContext, useMemo, useState } from 'react'
import Loader from '../../../../Constants/Loader';
import AlertPopUp from '../../../../Constants/AlertPopUp';
import AddTask from './AddTask';
import { AuthContext } from '../../../../Context/AuthContextProvider';
import { useDispatch, useSelector } from 'react-redux';
import { reloadPage } from '../../../../redux/actions/action.mobile/load.action';
import _ from 'lodash';

function ListTask() {
    //context
    const { authContext } = useContext(AuthContext);
    const { userid, username, userrole, email, userphone, companyname, clientimage, companyid, companyemail } = authContext.userData;

    //redux
    const dispatch = useDispatch();
    const { allProjectData } = useSelector((state) => state.projectReducer || {});
    const { allUserData } = useSelector((state) => state.userReducer);
    const loadData = useSelector((state) => state.loadReducer);

    const [searchKeyword, setSearchKeyword] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [filteredData, setFilteredData] = useState([]);
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState("");
    const [alertType, setAlertType] = useState("success");

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        dispatch(reloadPage(true));
        setDrawerOpen(false);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const debouncedSearch = useCallback(_.debounce(() => {
        setLoading(true);
        setTimeout(() => setLoading(false), 500);
    }, 300), []);

    const filteredData = useMemo(() => {
        if (allProjectData.length > 0) {
            debouncedSearch();
            const searchInObject = (obj, keyword) => {
                const searchStr = keyword.toString().toLowerCase().trim();
                return Object.values(obj).some(value => {
                    if (typeof value === 'object' && value !== null) {
                        return searchInObject(value, searchStr);
                    }
                    return value?.toString().toLowerCase().trim().includes(searchStr);
                });
            };
            return allProjectData?.filter(item => searchInObject(item, searchKeyword));
        } else {
            return [];
        }
    }, [searchKeyword, allProjectData]);

    // useEffect(() => {
    //     if (loadData) {
    //         dispatch(allproject(setAlertType, setOpen, setMsg));
    //     }

    //     if (companyemail) {
    //         const body = {
    //             companyemail: companyemail,
    //             companyid: companyid
    //         }
    //         dispatch(allUsers(body, setOpen, setMsg, setAlertType));
    //     }

    // }, [loadData, companyemail]);

    const columns = [
        { field: "taskname", headerName: "Task Name", flex: 1 },
        { field: "projectname", headerName: "Project Name", flex: 1 },
        { field: "createdby", headerName: "Created By", flex: 1 },
        { field: "date", headerName: "Date", flex: 1 },
    ];

    const rows =
        filteredData.length > 0
            ? filteredData.map((item) => ({
                id: item.id,
                taskname: item.projectname,
                projectname: item.createdby,
                createdby: item.description,
                date: item.date
            }))
            : [];

    return (
        <>
            <Box borderRadius={2} my={2} className="commonShadow">
                <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                    <Typography
                        variant={isSmallScreen ? "h6" : "h5"}
                        className="master-heading"
                        p={2}
                    >
                        List of All Task
                    </Typography>
                    <Box
                        display={"flex"}
                        // alignItems={"center"}
                        // justifyContent={"flex-end"}
                        p={1}
                    >
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            value={searchKeyword}
                            onChange={(e) => setSearchKeyword(e.target.value)}
                            style={{ marginRight: "16px" }}
                        />
                        <Button className="saveBtn" onClick={handleDrawerOpen}>
                            Add
                        </Button>
                    </Box>
                </Stack>
                <Divider />
                <Box my={1}>
                    <Paper style={{ height: "auto", width: "100%" }}>
                        <DataGrid
                            rows={loading ? [] : rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15, 20, rows.length]}
                            checkboxSelection
                            getRowId={(row) => row.id}
                            autoHeight
                            components={{
                                LoadingOverlay: Loader,
                            }}
                            loading={loading}
                        // onRowClick={handleRowClick}
                        />
                    </Paper>
                </Box>
            </Box>
            <AddTask
                drawerOpen={drawerOpen}
                handleDrawerClose={handleDrawerClose}
                // empInfo={empInfo}
                isSmallScreen={isSmallScreen}
            />
            <AlertPopUp
                open={open}
                msg={msg}
                handleClose={handleClose}
                type={alertType}
            />
        </>
    )
}

export default ListTask;