import {
    Box,
    Button,
    Drawer,
    Grid,
    TextField,
    Typography,
    Divider,
    IconButton,
    Stack,
    Autocomplete,
    Checkbox,
    Avatar,
    FormHelperText,
    Select,
    MenuItem,
} from "@mui/material";
import React, { lazy, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { addOrganisationgoalKpiMasterApi } from "../../../../Constants/api";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { AuthContext } from "../../../../Context/AuthContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { allUsers } from "../../../../redux/actions/actions.master/user.actions";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { stringProfileAvatar } from "../../../../Constants/StringAvatar";
import CommonDrawer from "../../../../Constants/CommonDrawer";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function AddTask({ drawerOpen, handleDrawerClose, isSmallScreen, empInfo }) {
    const cookieData = Cookies.get("userinfo");
    let userData = cookieData ? JSON.parse(cookieData) : {};
    const [emptyError, setShowEmptyError] = useState(false);
    //context
    const { authContext } = useContext(AuthContext);
    const { userid, username, userrole, email, userphone, companyname, clientimage, companyid, companyemail } = authContext.userData;

    //redux
    const dispatch = useDispatch();
    const { allUserData } = useSelector((state) => state.userReducer);


    //state
    const [data, setData] = useState({
        projectname: "",
        taskname: "",
        createdby: "",
        date: ""
    });
    const [error, setError] = useState({});
    const projectInitialdata = [
        { id: 1, projectname: "Project1" },
        { id: 2, projectname: "Project2" },
        { id: 3, projectname: "Project3" },
        { id: 4, projectname: "Project4" },
        { id: 5, projectname: "Project5" },
    ]
    const [projectAllData, setProjectAllData] = useState(projectInitialdata);
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState("");
    const [alertType, setAlertType] = useState("success");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
        setError((data) => ({ ...data, [name]: "" }));
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleAdd = async () => {
        // console.log(data, "data");
        try {
            let res = await axios.post(addOrganisationgoalKpiMasterApi, data, { withCredentials: true });
            if (res.data.message == "Kpi added successfully") {
                setAlertType("success");
                setOpen(true);
                setMsg("Organization goal kpi added");
                setTimeout(() => {
                    handleDrawerClose();
                    setData({
                        kpiname: "",
                        description: "",
                        companyemail,
                        companyid,
                        email: "",
                    })
                }, 1000);
            }
        } catch (error) {
            console.log(error, "error");
            setAlertType("error");
            setOpen(true);
            setMsg(error.response.data.message || "Something went wrong");
            // setTimeout(() => {
            //   handleDrawerClose();
            // }, 1000);
        }
    };

    const validate = () => {
        const newErrors = {};

        // Project name validation
        if (!data.projectname) {
            newErrors.projectname = "Project name is required";
        }

        // Project name validation
        if (!data.taskname) {
            newErrors.taskname = "Task name is required";
        }

        // Startdate validation
        if (!data.date) {
            newErrors.date = "Date cannot be empty";
        } else {
            const isValidDate = dayjs(data.date, "DD/MM/YYYY", true).isValid();
            if (!isValidDate) {
                newErrors.date = "Date must be a valid date in DD/MM/YYYY format";
            }
        }

        setError(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    useEffect(() => {
        const body = {
            companyemail: companyemail,
            companyid: companyid
        }
        if (username && drawerOpen) {
            setData((prev) => ({ ...prev, createdby: username }))
            dispatch(allUsers(body, setOpen, setMsg, setAlertType));
        }

    }, [dispatch, drawerOpen])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                console.log("data", data)
                // const res = await axios.post(`${isProduction ? `${hrmsDomain}/user/loginhrms` : LoginApi}`, data);
                // // console.log("res", res)
                // if (res.data.message == "Login Successful") {
                //     notifySuccess("Login Successfull");
                //     Cookies.set("companytoken", res.data.token, {
                //         domain: isProduction ? ".vliv.app" : "localhost",
                //         path: "/",
                //         secure: isProduction,
                //         sameSite: isProduction ? "None" : "Lax",
                //         expires: 1,
                //     });
                //     setAuthContext({ token: res.data.token, userData: res.data.data });

                //     const {
                //         password,
                //         confirmpassword,
                //         yearlypremium,
                //         yearlystandard,
                //         yearlybasic,
                //         premium,
                //         standard,
                //         basic,
                //         addonpack,
                //         featureslist,
                //         purchaseaddonpack,
                //         ...userinfo
                //     } = res.data.data;

                //     Cookies.set("userinfo", JSON.stringify(userinfo), {
                //         domain: isProduction ? ".vliv.app" : "localhost",
                //         path: "/",
                //         secure: true,
                //         sameSite: isProduction ? "None" : "Lax",
                //         expires: 1
                //     });
                //     // let role = res.data.data.userrole ? res.data.data.userrole : "";
                //     setTimeout(() => {
                //         navigate("/mobile/attendance");
                //         // navigate("/mobile/home");
                //     }, 500);
                // } else {
                //     notifyError(res.data.message)
                // }
            }
            catch (err) {
                console.log("Error while login", err.response.data.message)
                // notifyError(err.response.data.message)
            }


        } else {
            console.log("Validation failed", error);
        }
    }

    return (
        <>
            <CommonDrawer drawerOpen={drawerOpen} handleDrawerClose={handleDrawerClose} drawertitle={"Add Task"}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <label htmlFor="projectname">Project Name</label>
                            <Select
                                fullWidth
                                sx={{ marginTop: "1rem" }}
                                value={data.projectname}
                                onChange={handleChange}
                                name="projectname"
                                placeholder="Enter projectname"
                            >
                                <MenuItem value="">Select Project</MenuItem>
                                {projectAllData && projectAllData.length > 0 ? projectAllData.map((item, index) => {
                                    const { id, projectname } = item;
                                    return (
                                        <MenuItem key={id} value={projectname}>{projectname}</MenuItem>
                                    )
                                }) : <MenuItem value="">Select Project</MenuItem>
                                }
                            </Select>
                            {error.projectname && (
                                <FormHelperText sx={{ color: "#d32f2f" }}>{error.projectname}</FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label htmlFor="taskname">Task Name</label>
                            <TextField
                                type="text"
                                name="taskname"
                                placeholder="Enter Task name"
                                value={data.taskname}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                error={!!error.taskname}
                                helperText={error.taskname}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label htmlFor="createdby">Created By</label>
                            <TextField
                                type="text"
                                name="Created By"
                                value={data.createdby}
                                // onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                error={!!error.createdby}
                                helperText={error.createdby}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label htmlFor="date">Date</label>
                            <div className="datefeild">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={data.date ? dayjs(data.date) : null}
                                        onChange={(newValue) => {
                                            setData({ ...data, date: newValue ? newValue.format('DD/MM/YYYY') : '' });
                                            setError((prev) => ({
                                                ...prev,
                                                date: '',
                                            }));
                                        }}
                                        renderInput={(params) => (<TextField
                                            {...params}
                                            placeholder="DD/MM/YYYY"
                                            fullWidth
                                            margin="normal"
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: "DD/MM/YYYY",
                                            }}
                                        />)}

                                    />
                                </LocalizationProvider>
                                {error.date && (
                                    <FormHelperText sx={{ color: "#d32f2f" }}>{error.date}</FormHelperText>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12}
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            flexDirection="row-reverse"
                            flexWrap={"wrap"}
                            gap={1}
                            padding={1}
                        >
                            <Button type="submit" className="saveBtn">
                                Save
                            </Button>
                            <Button
                                onClick={handleDrawerClose}
                                sx={{ ml: 2 }}
                                className="cancelBtn"
                                variant="outlined"
                            >
                                Cancel
                            </Button>


                        </Grid>
                    </Grid>


                </form>


            </CommonDrawer>
            <AlertPopUp
                open={open}
                msg={msg}
                handleClose={handleClose}
                type={alertType}
            />
        </>
    )
}

export default AddTask