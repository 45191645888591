import React from 'react';
import ListProject from './ListProject';

function Project() {
    return (
        <div>
            <ListProject />
        </div>
    )
}

export default Project