import axios from 'axios';
import React, { useEffect, useState } from 'react'

function useFetch(url, options = {}) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    // console.log(options, "url")
    useEffect(() => {
        if (url) {
            fetchData();
        }

    }, [url, options]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const res = await axios.post(url, options, { withCredentials: true });
            if (res.statusText === "OK") {
                setData(res.data);
                setLoading(false);
            }

        }
        catch (error) {
            setLoading(false);
            setError(error?.response?.data.message);
            console.log(`Error while making api call ${error}`)
        }
    }

    return { data, loading, error }
}

export default useFetch;