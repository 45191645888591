import { ALL_PROJECT_DATA, ALL_TASK_DATA } from "../../actionTypes";

const initialState = {
    allProjectData: [],
    allTaskData: [],
}


export const projectReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ALL_PROJECT_DATA: {
            return { ...state, allProjectData: payload };
        }

        case ALL_TASK_DATA: {
            return { ...state, allTaskData: payload }
        }

        default: {
            return state;
        }
    }

};