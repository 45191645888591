import { ALL_USERS_DATA } from "../../actionTypes";

const initialState = {
    singleUser:{},
    allUserData: [],
};

export const userReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case ALL_USERS_DATA: {
            return { ...state, allUserData: payload };
        }

        default: {
            return state;
        }
    }

};