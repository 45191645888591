import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Grid,
  FormLabel,
} from "@mui/material";
import { AttachFile } from "@mui/icons-material";
import Cookies from "js-cookie";
import axios from "axios";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import Loader from "../../../../Constants/Loader";
import { vlivApi, vlivDomain } from "../../../../Constants/api";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Country, State, City } from "country-state-city";
import { useNavigate } from "react-router-dom";
const allCountries = Country.getAllCountries();

const PayslipLogo = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [position, setPosition] = useState("");
  const [imageURL, setImageURL] = useState("");
  // const [imageName, setImageName] = useState("");
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [selectedCountriesStates, setSelectedCountriesStates] = useState([]);
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const navigate = useNavigate()


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleImageUpload = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      // Process the files here
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      const res = await axios.post(
        "https://hrms.vliv.app/general/logo",

        formData, { withCredentials: true },
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      // console.log("res", res);
      if (res.data.file) {
        const fileurl = res.data.file;
        setImage(res.data.file);
        setAlertType("success");
        setOpen(true);
        setMsg("File uploaded..");
      }
    } else {
      setAlertType("error");
      setOpen(true);
      setMsg("Upload a file");
    }
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleSubmit = async (e) => {
    // Handle the submit logic here
    e.preventDefault();
    try {
      let res = await axios.post(`${process.env.NODE_ENV == "production" ? vlivDomain : vlivApi}/company/updateimage`, {
        companyid,
        position,
        companyimage: image,
        ...data
      }, { withCredentials: true });
      if (res.data && res.data.message && res.data.message == "Logo updated") {
        setAlertType("success");
        setOpen(true);
        setMsg("Payslip info updated successfully");
        getCompanyDetails();
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg("Error While updating image");
    }
  };

  const getImageAlignment = () => {
    switch (position) {
      case "top-left":
        return { justifyContent: "flex-start", alignItems: "flex-start" };
      case "top-right":
        return { justifyContent: "flex-end", alignItems: "flex-start" };
      case "bottom-left":
        return { justifyContent: "flex-start", alignItems: "flex-end" };
      case "bottom-right":
        return { justifyContent: "flex-end", alignItems: "flex-end" };
      case "top-center":
        return { justifyContent: "center", alignItems: "flex-start" };
      case "bottom-center":
        return { justifyContent: "center", alignItems: "flex-end" };
      default:
        return { justifyContent: "center", alignItems: "center" };
    }
  };

  const getCompanyDetails = async () => {
    try {
      setLoading(true);
      let res = await axios.post(`${process.env.NODE_ENV == "production" ? vlivDomain : vlivApi}/company/getcompanybyid`, {
        companyid,
      }, { withCredentials: true });
      // console.log(res, "company logo res");
      res.data && res.data.companyimage
        ? setImage(res.data.companyimage)
        : setImage("");
      res.data && res.data.position
        ? setPosition(res.data.position)
        : setPosition("");
      res.data && setData((prev) => ({ ...prev, ...res.data }));
      setLoading(false);
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg("Error while getting logo");
      console.log(error, "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // if (name == "country") {
    //   if (value) {
    //     console.log(value, "values")
    //     setData((prev) => ({ ...prev, state: "" }));
    //     const selectedCountry = Country.getCountryByCode(value);
    //     setData((prev) => ({
    //       ...prev,
    //       country: selectedCountry.name
    //     }));
    //     const states = State.getStatesOfCountry(selectedCountry.isoCode) || [];
    //     // console.log(states,"states")
    //     setSelectedCountriesStates((prev) => states)
    //   }
    //   // else {
    //   //   setData((prev) => ({
    //   //     ...prev,
    //   //     country: "",
    //   //     currency: "",
    //   //     countrycode: "",
    //   //   }));
    //   //   SetSelectedCountriesStates([]);
    //   //   setSelectedCountryTimezone([]);
    //   // }
    // }
    setData({ ...data, [name]: value });
    setError((data) => ({ ...data, [name]: "" }));
  };
  // console.log("data", data)
  return (
    <>
      <div>
        <Stack direction="row" justifyContent="space-between">
          <Box p={1} width={"50%"}>
            <Typography variant="h5" color="initial" marginY={"1rem"}>Payslip Logo</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 3,
                padding: 3,
                border: "1px solid #ccc",
                borderRadius: "8px",
                width: "400px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <TextField
                // label="Upload Image"
                value={image}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <input
                        accept="image/jpeg, image/png, image/gif"
                        id="upload-image"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                      <label htmlFor="upload-image">
                        <IconButton aria-label="upload image" component="span">
                          <AttachFile />
                        </IconButton>
                      </label>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                fullWidth
              />

              <FormControl fullWidth>
                <InputLabel id="position-select-label">Position</InputLabel>
                <Select
                  labelId="position-select-label"
                  value={position}
                  label="Position"
                  onChange={handlePositionChange}
                >
                  <MenuItem value="top-left">Top Left</MenuItem>
                  <MenuItem value="top-right">Top Right</MenuItem>
                  <MenuItem value="bottom-left">Bottom Left</MenuItem>
                  <MenuItem value="bottom-right">Bottom Right</MenuItem>
                  <MenuItem value="top-center">Top Center</MenuItem>
                  <MenuItem value="bottom-center">Bottom Center</MenuItem>
                </Select>
              </FormControl>

              <Box sx={{ textAlign: "right" }} width={1}>
                <Button className="saveBtn" onClick={handleSubmit}>
                  Save
                </Button>
              </Box>
            </Box>

            {loading ? (
              <Loader />
            ) : (
              image && (
                <Box
                  sx={{
                    marginTop: 3,
                    width: "400px",
                    height: "300px",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: getImageAlignment().justifyContent,
                    alignItems: getImageAlignment().alignItems,
                    overflow: "hidden",
                    padding: 2,
                  }}
                >
                  <img
                    src={image}
                    alt="company_logo"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                  />
                </Box>
              )
            )}
          </Box>
          <Box p={1} width={"50%"}>
            <Typography variant="h5" color="initial" marginY={"1rem"}>Company Details</Typography>
            <Grid container>
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <Grid item md={12}>
                  <label htmlFor="company">Company Name</label>
                  <TextField
                    type="text"
                    name="company"
                    placeholder="Enter Company name"
                    value={data.company}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!error.company}
                    helperText={error.company}
                  />
                </Grid>
                <Grid item md={12}>
                  <label htmlFor="companyemail">Company Email</label>
                  <TextField
                    type="email"
                    name="companyemail"
                    placeholder="Enter Company email"
                    value={data.companyemail}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!error.companyemail}
                    helperText={error.companyemail}
                  />
                </Grid>
                <Grid item md={12}>
                  <label htmlFor="address">Address</label>
                  <TextField
                    type="text"
                    name="address"
                    placeholder="Enter Address"
                    value={data.address}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!error.address}
                    helperText={error.address}
                  />
                </Grid>
                {/* <Grid item md={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="normal"
                  >
                    <label htmlFor="country" >Country</label>
                    <Select
                      id="industry-type"
                      onChange={handleChange}
                      name="country"
                      fullWidth
                      margin="normal"
                    >
                      {allCountries?.map((country, ind) => (
                        <MenuItem
                          value={country.isoCode}
                          key={ind}
                          textAlign="left"
                        >
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="normal"
                  >
                    <label htmlFor="state">State</label>

                    <Select
                      value={data.state}
                      onChange={handleChange}
                      name="state"
                    >
                      {selectedCountriesStates?.map((state, ind) => (
                        <MenuItem
                          value={state.name}
                          key={ind}
                          textAlign="left"
                        >
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item md={12}>
                  <label htmlFor="pincode">Pincode</label>
                  <TextField
                    type="number"
                    name="pincode"
                    placeholder="Enter Pincode"
                    value={data.pincode}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!error.pincode}
                    helperText={error.pincode}
                  />
                </Grid>
                <Grid item md={12}>
                  <label htmlFor="phoneno">Phone no</label>
                  <TextField
                    type="text"
                    name="phoneno"
                    placeholder="Enter Phone no"
                    value={data.phoneno}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!error.phoneno}
                    helperText={error.phoneno}
                  />
                </Grid>
                <Grid item md={12}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  flexDirection="row-reverse"
                  flexWrap={"wrap"}
                  gap={1}
                  padding={1}
                >
                  <Button type="submit" className="saveBtn">
                    Save
                  </Button>
                  <Button
                    onClick={() => navigate(-1)}
                    sx={{ ml: 2 }}
                    className="cancelBtn"
                    variant="outlined"
                  >
                    Cancel
                  </Button>


                </Grid>
              </form>
            </Grid>

          </Box>
        </Stack>
      </div>

      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default PayslipLogo;
