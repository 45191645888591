import axios from "axios"; // For making HTTP requests
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";
import Cookies from "js-cookie";
import { TbReportMoney } from "react-icons/tb";

import AlertPopUp from "../../../../Constants/AlertPopUp";
import { getPayrollInputsApi, vlivApi, vlivDomain } from "../../../../Constants/api";
import { convertToIndianCurrencyWords } from "../../../../Constants/constants";
import { formatDateTime } from "../../../../Constants/dateFormat";
import styles from "./salarySlipComponent.module.css";

const SalarySlipComponent = ({ listData, data, setData, currentDate }) => {
  // console.log("listData", listData)
  const [loading, setLoading] = useState(false);
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let companyname = userData.companyname ? userData.companyname : "";
  let address = userData.address ? userData.address : "";

  const [companyDetails, setCompanyDetails] = useState({});
  // console.log("companyDetails", companyDetails);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const uploadPdfToBackend = async (pdfBlob, employee) => {
    // console.log(pdfBlob, "blob pdf");
    try {
      const formData = new FormData();
      formData.append("file", pdfBlob, `${employee.name}_SalarySlip.pdf`);
      const response = await axios.post(
        "https://hrms.vliv.app/payroll/salaryslipupload",
        formData, { withCredentials: true },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data && response.data.file) {
        try {
          let res = await axios.post(
            "https://hrms.vliv.app/payroll/generatesalaryslip",
            { id: employee.id, payslip: response.data.file }, { withCredentials: true }
          );
          // console.log(res, "update payslip");
        } catch (error) {
          console.log(error, "error");
        }
      }

    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  const generateAndSendPdfToBackend = async (employee) => {
    const element = document.getElementById(`payslip-${employee.id}`);
    if (!element) return;

    try {
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imgProps = pdf.getImageProperties(imgData);
      const imgWidth = pdfWidth;
      const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      const pdfBlob = pdf.output("blob");

      await uploadPdfToBackend(pdfBlob, employee);
    } catch (error) {
      console.log("Error generating or sending PDF:", error);
    } finally {
      element.style.position = "absolute";
      element.style.left = "-9999px";
    }
  };

  const getData = async () => {
    try {
      let res = await axios.post(getPayrollInputsApi, {
        companyemail,
        companyid,

        month: currentDate.format("MMMM"),
        year: currentDate.format("YYYY"),

      }, { withCredentials: true });
      res.data && res.data.length > 0 ? setData(res.data) : setData([]);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadAllSalarySlips = async () => {
    try {
      setLoading(true);

      for (const employee of listData) {
        await generateAndSendPdfToBackend(employee);
      }

      setLoading(false);
      setOpen(true);
      setAlertType("success");
      setMsg("Process Completed");
      getData();
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
      setOpen(true);
      setAlertType("error");
      setMsg(error.response.data.message || "Something went wrong");
      getData();
    }
  };

  const getCompanyDetails = async () => {
    try {
      let res = await axios.post(`${process.env.NODE_ENV == "production" ? vlivDomain : vlivApi}/company/getcompanybyid`, {
        companyid,
      }, { withCredentials: true });

      res.data && res.data.companyimage
        ? setCompanyDetails(res.data)
        : setCompanyDetails({});
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  const earningsKeys = [
    "basicpay",
    "houserentpay",
    "specialpay",
    "conveyancepay",
    "otherallowancepay",
    "educationpay",
    "travelpay",
    "internetpay",
    "medicalpay",
    "vehiclepay",
    "insurancepay",
    "incentive",
  ];

  const deductionsKeys = [
    "pfemployeepay",
    "esicemployeepay",
    "ptaxpay",
    "tdspay",
    "lop",
    "empdeduction",
    "otherdeductionpay",
  ];

  const formatKeyLabel = (key) => {
    switch (key) {
      case "basicpay":
        return "Basic";
      case "houserentpay":
        return "House Rent Allowance";
      case "specialpay":
        return "Special Allowance";
      case "conveyancepay":
        return "Conveyance Allowance";
      case "otherallowancepay":
        return "Other Allowance";
      case "educationpay":
        return "Education Allowance";
      case "travelpay":
        return "Travel Allowance";
      case "internetpay":
        return "Internet Reimbursement";
      case "medicalpay":
        return "Medical Reimbursement";
      case "vehiclepay":
        return "Vehicle Allowance";
      case "insurancepay":
        return "Health Insurance";
      case "incentive":
        return "Incentive";
      case "pfemployeepay":
        return "Provident Fund";
      case "esicemployeepay":
        return "ESIC Employee";
      case "ptaxpay":
        return "Professional Tax";
      case "tdspay":
        return "TDS";
      case "lop":
        return "LOP";
      case "empdeduction":
        return "Employee Deductions";
      case "otherdeductionpay":
        return "Other Deductions";
      default:
        return key;
    }
  };

  return (
    <>
      <div>
        {listData.map((employee) => {
          // console.log("employee", employee)
          const filteredEarnings = earningsKeys.filter(
            (key) => employee[key] && employee[key] !== 0 && employee[key] !== "0"
          );

          const filteredDeductions = deductionsKeys.filter(
            (key) => employee[key] && employee[key] !== 0 && employee[key] !== "0"
          );

          return (
            <div
              key={employee.id}
              className="hidden"
              style={{ position: "relative" }}
            >
              <div
                id={`payslip-${employee.id}`}
              className={`${styles.payslip} hidden `}
              >
                <div style={{ border: "3px solid #026FB6" }}>
                  <div className={styles.payslipHeader}>
                    <img
                      src={companyDetails.companyimage || ""}
                      alt="Company_Logo"
                      className={styles.payslipLogo}
                    />
                    <div className={styles.companyInfo}>
                      <h2>{companyDetails.company || ""}</h2>
                      <p style={{ margin: "2px 0px" }}>{companyDetails.address || `Office no. 220 Floor, Forum Galleria Mall, Civil Township, Rourkela, 769004`}</p>
                      <div style={{ display: "flex", gap: "1rem" }}>
                        <p style={{ margin: "2px 0px" }}>Email: {companyDetails.companyemail || "people@venturesathi.com"}</p>
                        <p style={{ margin: "2px 0px" }}>Phone no.: {companyDetails.phoneno}</p>
                      </div>

                    </div>
                  </div>
                  <div>
                    <h4 className={styles.payslipTitle}>PAYSLIP FOR THE MONTH OF {employee.month?.toUpperCase()} {employee.year}</h4>
                  </div>

                  <table className={styles.infoTable}>
                    <tbody>
                      <tr>
                        <td>
                          Employee ID: <span>{employee.employeeid}</span>
                        </td>
                        <td>
                          PAN No.:<span>{employee.pan || ""}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Employee Name: <span>{employee.name}</span>
                        </td>
                        <td>
                          Bank Account No.: <span>{employee.accountno || ""}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Designation: <span>{employee.position}</span>
                        </td>
                        <td>
                          IFSC Code: <span>{employee.ifsc}</span>
                        </td>

                      </tr>
                      <tr>
                        <td>
                          Joining Date: <span>{employee.joiningdate || ""}</span>
                        </td>
                        <td>
                          ESI No.: <span>{employee.esic || ""}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Total Days: <span>30</span>
                        </td>
                        <td>
                          UAN No.: <span>{employee.uan || ""}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Pay Days: <span>{employee.paydays || 30}</span>
                        </td>
                        <td>
                          Payment Mode:{" "}
                          <span>{employee.paymenttype || "NEFT"}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          LOP: <span>{employee.lop || 0}</span>
                        </td>
                        <td>
                          Payslip Generated On:{" "}
                          <span>{formatDateTime(new Date())}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table className={styles.earningsDeductions}>
                    <thead>
                      <tr>
                        <th>Earnings</th>
                        <th>Amount</th>
                        <th>Deductions</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Render Earnings Rows */}
                      {filteredEarnings.map((key, index) => (
                        <tr key={`earnings-${index}`}>
                          <td>{formatKeyLabel(key)}</td>
                          <td>{employee[key]}</td>
                          {index < filteredDeductions.length ? (
                            <>
                              <td>{formatKeyLabel(filteredDeductions[index])}</td>
                              <td>{employee[filteredDeductions[index]]}</td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                            </>
                          )}
                        </tr>
                      ))}

                      {/* Render Remaining Deductions Rows */}
                      {filteredDeductions.slice(filteredEarnings.length).map((key, index) => (
                        <tr key={`deductions-${index}`}>
                          <td></td>
                          <td></td>
                          <td>{formatKeyLabel(key)}</td>
                          <td>{employee[key]}</td>
                        </tr>
                      ))}

                      {/* Render Total Earnings and Deductions */}
                      {(filteredEarnings.length > 0 || filteredDeductions.length > 0) && (
                        <tr>
                          <td>
                            <strong>Total Earnings</strong>
                          </td>
                          <td>
                            <strong>{employee.earning || "-"}</strong>
                          </td>
                          <td>
                            <strong>Total Deductions</strong>
                          </td>
                          <td>
                            <strong>{employee.deduction || "-"}</strong>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>


                  <div className={styles.payslipFooter}>
                    <p>
                      <strong style={{ fontSize: "14px" }}>
                        &nbsp; Netpay:&nbsp;
                      </strong>{" "}
                      {Math.ceil(employee.earning - employee.deduction)}
                    </p>
                    <p>
                      <strong style={{ fontSize: "14px" }}>
                        &nbsp; Inwords:&nbsp;
                      </strong>{" "}
                      {`${convertToIndianCurrencyWords(
                        Math.ceil(employee.earning - employee.deduction)
                      )}`}
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    margin: "20px 0px",
                    textAlign: "center",
                    position: "absolute",
                    bottom: 0,
                  }}
                >
                  <strong>
                    **This is a system generated payslip and does not require any
                    signature.**
                  </strong>
                </div>
              </div>
            </div>
          );
        })}
        {!loading ? (
          <Button
            startIcon={<TbReportMoney />}
            className="saveBtn"
            onClick={downloadAllSalarySlips}
          >
            Generate Payslip
          </Button>
        ) : (
          <Button startIcon={<TbReportMoney />} className="saveBtn">
            Generating...
          </Button>
        )}
      </div>

      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default SalarySlipComponent;
